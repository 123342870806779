import React from 'react';
import {
  Text,
  Box,
} from '@chakra-ui/react';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';

function DocumentsManagement() {
  useScrollToTop();

  return (
    <Box
      mt={{ sm: '2%', md: '1%', lg: '1%' }}
      maxW="900px"
      mx="auto"
      height="70%"
      width="100%"
      zIndex="1"
      px={4}
      textAlign="justify"
    >
      <PageHeader title="Internal Policy & Management of Obsolete Documents" />
      <Text lineHeight="24px" fontSize="18px" fontWeight="600" mb="10px">
        MNPL- Internal Document Management Policy
      </Text>
      <Text lineHeight="24px" fontSize="18px" fontWeight="600" mb="40px">
        Effective Date: 01.01.2023      
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        1. Objective:
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        This policy aims to establish clear and transparent guidelines for document management within MNPL.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        2. Management of Obsolete Documents:
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        Any documentation older than one year is considered obsolete unless stated otherwise. Obsolescence criteria may include the creation date, relevance of information it contains, or other factors specific to our company. Employees are required to comply with this policy.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        3. Obsolescence Criteria:
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        Obsolescence criteria are established by management and may include creation date, relevance of information, and other factors specific to our company.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        4. Communication to Employees:
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        All employees will be informed of this policy through regular training sessions. Understanding this policy is mandatory for all staff members.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        5. Management of Archives:
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        Obsolete documents will be managed in accordance with procedures established by management. This may include secure document destruction or offline archiving.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        6. Legal Considerations:
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        This policy is established taking into account local laws and regulations regarding document retention. MNPL is committed to complying with all legal obligations related to information management.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        7. Security of Sensitive Information:
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        Even if a document is considered obsolete, if it contains sensitive information, it will continue to be protected according to our existing information security policies.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        8. Non-Disclosure and Non-Compete Clause:
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        Any person who has received or had access to company documents is bound by the non-disclosure and non-compete clause. In the event of external use, the individual will be subject to legal action and a minimum damages amount of 10,000 CHF.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        9. Periodic Review:
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        This policy will be periodically reviewed to ensure its relevance and adaptation to the company's needs. Any modifications will be communicated to employees proactively.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        10. Acceptance of the Policy:
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        As a sign of acceptance and understanding of this policy, each employee is required to read and sign a declaration confirming compliance with these directives. If  he refuses or does not sign this policy, the company will be obliged to terminate the collaboration.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        11. Contact:
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        For any questions or concerns regarding this policy, please contact Djamel Thiam at compliance.support@monopole.network 
      </Text>
    </Box>
  );
}

export default DocumentsManagement;
