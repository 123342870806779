import { Flex, Box, Text, Image, useColorModeValue } from '@chakra-ui/react';
import Tag from './Tag';

const ProjectCard = ({ tags, title, description, imgSrc, altText }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignContent="center"
      backgroundColor={useColorModeValue('white', 'black')}
      borderRadius="2xl"
    >
      <Flex direction="column" justify="center" width="40%" flexGrow={1}>
        <Flex mx={{ base: 6, sm: 6, md: 4, lg: 6, xl: 6 }} gap={1}>
          {tags.map((tag, index) => (
            <Tag key={index} shouldTruncate={true}>
              {tag}
            </Tag>
          ))}
        </Flex>
        <Text
          mx={{ base: 6, sm: 6, md: 4, lg: 6, xl: 6 }}
          fontSize={{ base: 'md', sm: 'lg', lg: 'xl', xl: '2xl' }}
          mt={4}
          fontWeight="medium"
        >
          {title}
        </Text>
      </Flex>
      <Box
        height="150px"
        width={{
          base: '150px',
          sm: '200px',
          md: '300px',
          lg: '200px',
          xl: '250px',
        }}
        overflow="hidden"
        borderRightRadius="2xl"
        alignSelf="center"
      >
        <Image
          src={imgSrc}
          alt={altText}
          objectFit="cover"
          objectPosition="center"
          height="100%"
          width="100%"
        />
      </Box>
    </Flex>
  );
};

export default ProjectCard;
