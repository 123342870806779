import { ChevronDownIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import { useBreakpointValue } from '@chakra-ui/media-query';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { IconButton } from '@chakra-ui/button';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { VStack } from '@chakra-ui/layout';
import { Collapse } from '@chakra-ui/transition';
import { useEffect, useRef, useState } from 'react';
import {
  FaAngleDown,
  FaAngleUp,
  FaExternalLinkAlt,
  FaGlobe,
} from 'react-icons/fa';

import { MonopoleIcon, MonopoleIconText } from '../Icons/Icons';
import { Social } from '../Social';

const SubmenuButton = ({ title, submenuItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const submenuRef = useRef(null);
  let timeoutId;

  useEffect(() => {
    setIsOpen(false);
  }, [submenuItems]);

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setIsOpen(false);
    }, 50);
  };

  const handleSubMenuMouseEnter = () => {
    clearTimeout(timeoutId);
  };

  const handleSubMenuMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setIsOpen(false);
    }, 50);
  };

  return (
    <Box position="relative" display="inline-block" mt="20px">
      <Button
        variant="ghost"
        display={{ base: 'none', md: 'block', lg: 'block' }}
        _hover={{ background: 'transparent' }}
        _active={{ background: 'transparent' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Text fontSize="14px" fontWeight="700">
          {title}
        </Text>
        <ChevronDownIcon ml={1} boxSize={4} />
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Box
          ref={submenuRef}
          position="absolute"
          top="calc(100% + 5px)"
          left="50%"
          transform="translateX(-50%)"
          borderRadius="8px"
          background={useColorModeValue('white', '#000126')}
          boxShadow={useColorModeValue(
            '0px 4px 12px rgba(0, 0, 0, 0.1)',
            '0px 4px 12px rgba(255, 255, 255, 0.1)'
          )}
          border={useColorModeValue(
            '1px solid rgba(0, 0, 0, 0.2)',
            '1px solid rgba(255, 255, 255, 0.2)'
          )}
          py={2}
          zIndex="1"
          onMouseEnter={handleSubMenuMouseEnter}
          onMouseLeave={handleSubMenuMouseLeave}
        >
          {submenuItems.map((item) =>
            item.link.startsWith('http') ? (
              <Link
                key={item.id}
                href={item.link}
                isExternal
                _focus={{ boxShadow: 'none' }}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  as={Button}
                  variant="ghost"
                  display={{ base: 'none', md: 'block', lg: 'block' }}
                  _hover={{ background: 'transparent', color: '#03CB88' }}
                  _active={{ background: 'transparent' }}
                  background="transparent"
                >
                  <Text
                    fontSize="14px"
                    fontWeight="700"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span>{item.label}</span>
                    <FaExternalLinkAlt
                      style={{ fontSize: '12px', marginLeft: '5px' }}
                    />
                  </Text>
                </Button>
              </Link>
            ) : (
              <HashLink
                key={item.id}
                to={item.link}
                scroll={() => {
                  const nextSection = document.getElementById(
                    item.link.replace('/#', '') + '-container'
                  );
                  const layoutContainer =
                    document.querySelector('.layoutContainer');

                  if (layoutContainer) {
                    const topPosition =
                      nextSection.getBoundingClientRect().top +
                      layoutContainer.scrollTop;

                    layoutContainer.scrollTo({
                      top: topPosition - 110,
                      behavior: 'smooth',
                    });
                  }
                }}
                smooth
              >
                <Link
                  as={Button}
                  variant="ghost"
                  display={{ base: 'none', md: 'block', lg: 'block' }}
                  _hover={{ background: 'transparent', color: '#03CB88' }}
                  _active={{ background: 'transparent' }}
                  background="transparent"
                >
                  <Text fontSize="14px" fontWeight="700">
                    {item.label}
                  </Text>
                </Link>
              </HashLink>
            )
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

const MobileMenu = ({ menuData, titleMenu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <>
      <IconButton
        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
        variant="ghost"
        _hover={{ background: 'transparent' }}
        _active={{ background: 'transparent' }}
        onClick={handleToggle}
        aria-label="Toggle Mobile Menu"
        position="fixed"
        top="25px"
        left="15px"
        zIndex={9999}
        fontSize="30px"
      />
      <Collapse in={isOpen} animateOpacity>
        <Flex
          pos="fixed"
          top={0}
          left={0}
          h="100vh"
          w="100vw"
          bg={useColorModeValue('white', '#000126')}
          p={4}
          zIndex={9998}
          pt="60px"
          justifyContent="center"
          alignItems="center"
        >
          <VStack alignItems="start" spacing={0}>
            {Object.keys(menuData).map((key) => (
              <VStack key={key} alignItems="start" spacing={0}>
                <Button
                  variant="ghost"
                  _active={{ background: 'transparent' }}
                  _hover={{ background: 'transparent' }}
                  background="transparent"
                  fontSize="20px"
                  fontWeight="bold"
                  pointerEvents="none"
                  height="30px"
                  color="#03CB88"
                >
                  {titleMenu[key]}
                </Button>
                {menuData[key].map((item) =>
                  item.link.startsWith('http') ? (
                    <Link
                      key={item.id}
                      href={item.link}
                      isExternal
                      _focus={{ boxShadow: 'none' }}
                      style={{
                        textDecoration: 'none',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        as={Button}
                        variant="ghost"
                        _hover={{ background: 'transparent', color: '#03CB88' }}
                        _active={{ background: 'transparent' }}
                        fontSize="16px"
                        fontWeight="500"
                        background="transparent"
                        ml="30px"
                        height={window.innerHeight < 750 ? '28px' : '38px'}
                      >
                        <Text
                          fontSize="16px"
                          fontWeight="700"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <span>{item.label}</span>
                          <FaExternalLinkAlt
                            style={{ fontSize: '12px', marginLeft: '5px' }}
                          />
                        </Text>
                      </Button>
                    </Link>
                  ) : (
                    <HashLink
                      key={item.id}
                      to={item.link}
                      scroll={(el) => {
                        const yCoordinate =
                          el.getBoundingClientRect().top + window.pageYOffset;
                        window.scrollTo({
                          top: yCoordinate - 230,
                          behavior: 'smooth',
                        });
                      }}
                      smooth
                    >
                      <Button
                        variant="ghost"
                        _hover={{ background: 'transparent', color: '#03CB88' }}
                        _active={{ background: 'transparent' }}
                        background="transparent"
                        fontSize="16px"
                        fontWeight="500"
                        ml="30px"
                        height={window.innerHeight < 750 ? '28px' : '38px'}
                        onClick={() => setIsOpen(false)}
                      >
                        {item.label}
                      </Button>
                    </HashLink>
                  )
                )}
              </VStack>
            ))}
          </VStack>
        </Flex>
      </Collapse>
    </>
  );
};

export default function Navbar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const menuColor = useColorModeValue('white', '#000126');
  const submenuData = {
    // proofOfImpact: [
    //   { id: 1, label: "Charter", link: "/charter" },
    //   { id: 2, label: "Qualification", link: "https://app.monopole.network/services/mentor-program" },
    //   { id: 3, label: "Label", link: "/services/label" },
    //   { id: 4, label: "Club", link: "/club" },
    //   { id: 5, label: "Foundation", link: "/foundation" },
    // ],
    ecosystem: [
      { id: 1, label: 'About us', link: '/about-us' },
      { id: 2, label: 'Roadmap', link: '/roadmap' },
      { id: 3, label: 'Meet the Team', link: '/team' },
      { id: 4, label: 'Documents', link: '/documents' },
      { id: 5, label: 'Blog', link: 'https://blog.monopole.network/' },
      // { id: 6, label: "Media Kit", link: "/" },
    ],
    impact: [{ id: 1, label: 'Charter', link: '/charter' }],
    services: [
      //   { id: 1, label: "Mentor Program", link: "/services/mentor-program" },
      { id: 2, label: 'Label', link: '/services/label' },
      { id: 3, label: 'Business', link: '/services/business' },
    ],
    // partners: [
    //   { id: 1, label: "Partners", link: "/partners" },
    // ],
  };

  const titleMenu = {
    proofOfImpact: 'Proof of Impact™',
    ecosystem: 'Ecosystem',
    impact: 'Impact',
    services: 'Services',
    partners: 'Partners',
  };

  /*const breakpoint = useBreakpointValue({
    base: 'base',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    '2xl': '2xl',
  });*/

  return (
    <Box
      px={{ base: '10px', sm: '20px', md: '25px', lg: '30px' }}
      position="fixed"
      left="0px"
      right="0px"
      top="0px"
      height="95px"
      zIndex="2000"
      borderBottom="1px solid rgba(130, 53, 255, .1)"
      backdropFilter="blur(25px)"
    >
      {/*<Box
        position="fixed"
        top="1rem"
        right="35rem"
        px="4"
        py="2"
        backgroundColor="rgba(0, 0, 0, 0.7)"
        color="white"
        borderRadius="md"
        fontSize="sm"
        zIndex="tooltip"
      >
        {breakpoint}
      </Box>*/}
      <Flex height="100%" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Flex display={{ base: 'none', md: 'none', lg: 'block' }}>
            <Button
              height="25px"
              width="180px"
              background="transparent"
              _hover={{ background: 'transparent' }}
              _active={{ background: 'transparent' }}
              onClick={() => {
                navigate('/');
              }}
            >
              <MonopoleIconText height="25px" width="180px" />
            </Button>
          </Flex>
          <Box px="25px" display={{ base: 'block', md: 'none', lg: 'none' }}>
            <MobileMenu menuData={submenuData} titleMenu={titleMenu} />
          </Box>
          <Flex display={{ base: 'block', md: 'block', lg: 'none' }}>
            <Button
              height="43px"
              width="43px"
              background="transparent"
              _hover={{ background: 'transparent' }}
              _active={{ background: 'transparent' }}
              onClick={() => {
                navigate('/');
              }}
            >
              <MonopoleIcon height="19px" width="43px" zIndex="10000" />
            </Button>
          </Flex>
          <Flex align="center">
            <Divider
              ml="30px"
              height="40px"
              orientation="vertical"
              mr="10px"
              bg="#353945"
              display={{ base: 'none', md: 'block', lg: 'block' }}
            />
            <ButtonGroup display={{ base: 'none', md: 'block', lg: 'block' }}>
              <Flex
                justifyContent="center"
                textAlign="center"
                alignItems="center"
              >
                <Link
                  href={'/'}
                  _hover={{ background: 'transparent', color: '#03CB88' }}
                >
                  <Button
                    variant="ghost"
                    display={{ base: 'none', md: 'block', lg: 'block' }}
                    _hover={{ background: 'transparent', color: '#03CB88' }}
                    _active={{ background: 'transparent' }}
                  >
                    <Text fontSize="14px" fontWeight="700">
                      Home
                    </Text>
                  </Button>
                </Link>
                {/* <SubmenuButton title="Proof of Impact™" submenuItems={submenuData.proofOfImpact} /> */}
                <SubmenuButton
                  title="Ecosystem"
                  submenuItems={submenuData.ecosystem}
                />
                <SubmenuButton
                  title="Impact"
                  submenuItems={submenuData.impact}
                />
                <SubmenuButton
                  title="Services"
                  submenuItems={submenuData.services}
                />
                {/* <Link href={"/#/partners"} _hover={{ background: "transparent", color: "#03CB88" }} mb="20px">
                    <Button
                      variant="ghost"
                      display={{ base: "none", md: "block", lg: "block" }}
                      _hover={{ background: "transparent", color: "#03CB88" }}
                      _active={{ background: "transparent" }}
                    >
                      <Text fontSize="14px" fontWeight="700">
                        Partners
                      </Text>
                    </Button>
                  </Link> */}
              </Flex>
            </ButtonGroup>
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          //border="2px solid #03CB88"
          borderRadius="50px"
          h="44px"
          w="210px"
          zIndex="10000"
          justify="space-between"
        >
          <Tooltip hasArrow label="" shouldWrapChildren mt="3">
            <Link href={'https://app.monopole.network'} isExternal>
              <Button
                borderRadius="50px"
                background="transparent"
                _hover={{ background: 'transparent' }}
                _active={{ background: 'transparent' }}
              >
                <Text fontSize="14px" fontWeight="700">
                  Connect
                </Text>
              </Button>
            </Link>
          </Tooltip>
          <Menu>
            <MenuButton display={{ base: 'block', md: 'block', lg: 'block' }}>
              <Flex align="center" background="transparent" borderRadius="50px">
                <Icon h="18px" w="18px" as={FaGlobe} mr="10px" />
                <Flex flexDirection="column">
                  <Icon h="12px" w="12px" as={FaAngleUp} />
                  <Icon h="12px" w="12px" as={FaAngleDown} />
                </Flex>
              </Flex>
            </MenuButton>
            <MenuList
              borderRadius="20px"
              alignItems="center"
              bg={menuColor}
              border="0px"
            >
              <Flex flexDirection="column">
                {Object.keys(Social).map((id) => (
                  <Link key={id} href={Social[id].link} isExternal>
                    <MenuItem borderRadius="20px" _hover={{ border: '0px' }}>
                      <Icon h="18px" w="18px" as={Social[id].logo} mr="10px" />
                      <Text fontSize="md">{Social[id].name}</Text>
                    </MenuItem>
                  </Link>
                ))}
              </Flex>
            </MenuList>
          </Menu>
          <Button
            background="transparent"
            onClick={toggleColorMode}
            borderRadius="50px"
            _hover={{ background: 'transparent' }}
            _active={{ background: 'transparent' }}
          >
            {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          </Button>
          <Skeleton
            //opacity="1"
            borderRadius="50px"
            position="absolute"
            w="inherit"
            h="inherit"
            zIndex="-2"
            startColor="#03CB88"
            endColor="#8235FF"
          />
          <Flex
            //opacity="1"
            borderRadius="50px"
            position="absolute"
            w="204px"
            ml="3px"
            h="39px"
            zIndex="-1"
            bg={menuColor}
          />
        </Flex>
      </Flex>
    </Box>
  );
}
