import React from 'react';
import { Text, Box, Flex, Link, Button, Image } from '@chakra-ui/react';
import background from '../../assets/img/plant_background.png';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';

function Business() {
  useScrollToTop();

  return (
    <Box
      mt={{ sm: '2%', md: '1%', lg: '1%' }}
      maxW="900px"
      mx="auto"
      height="70%"
      width="100%"
      zIndex="1"
      px={4}
    >
      <PageHeader title="Business" />
      <Flex direction={{ sm: 'column', md: 'row' }} w="100%" mb="50px">
        <Box
          w={{ md: '50%' }}
          display={{ base: 'none', sm: 'none', md: 'block' }}
        >
          <Image
            src={background}
            width="100%"
            height="auto"
            borderRadius="10px"
          />
        </Box>
        <Box
          w={{ sm: '100%', md: '50%' }}
          textAlign="justify"
          mt={{ sm: '20px', md: '0' }}
        >
          <Text lineHeight="24px" fontSize="20px" fontWeight="400" mb="25px">
            Monopole is both a constant watch on the entire ecosystem but also a
            bridge between projects and innovation.
          </Text>
          <Text lineHeight="24px" fontSize="20px" fontWeight="400" mb="25px">
            All its parameters overlap through impact and our label, the Proof
            of Impact™.
          </Text>
          <Text lineHeight="24px" fontSize="20px" fontWeight="400" mb="25px">
            Today, Monopole has the opportunity to provide solutions not only to
            professionals but also to institutions.
          </Text>
          <Text lineHeight="24px" fontSize="20px" fontWeight="400" mb="10px">
            We help on various services such as:
          </Text>
          <Text
            lineHeight="28px"
            fontSize="20px"
            fontWeight="400"
            mb="10px"
            mt="20px"
            as="ul"
            pl="24px"
          >
            <li style={{ marginBottom: '10px' }}>
              <b>Whitelabel production</b> of blockchain products/services
            </li>
            <li style={{ marginBottom: '10px' }}>
              <b>Sourcing</b> and <b>linking solutions</b> to solve national or
              international problems
            </li>
            <li style={{ marginBottom: '10px' }}>
              Calls for projects to build a <b>portfolio dedicated</b> to the
              client’s attractions
            </li>
            <li style={{ marginBottom: '10px' }}>
              Brokerage and dedicated account manager to <b>make life easier</b>{' '}
              for clients who don t have the structure or time
            </li>
            <li style={{ marginBottom: '10px' }}>
              Creation of financial products/services such as{' '}
              <b>tokenisation, vault</b> or other for professionals
            </li>
            <li style={{ marginBottom: '10px' }}>
              <b>Customization</b> of the service according to the demand,
              talking about it is already starting to create!
            </li>
          </Text>
          <Flex
            mt="40px"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Link
              href={'mailto:justine.houge@monopole.network'}
              width="300px"
              height="64px"
              isExternal
              target="_blank"
              _focus={{ boxShadow: 'none' }}
            >
              <Button
                borderRadius="16px"
                background="#03CB88"
                width="100%"
                height="100%"
                color="white"
                _active={{
                  background: '#02B577', // Darkened for active state
                  transform: 'scale(0.98)', // Slightly scale down for a "pressed" effect
                }}
                _hover={{
                  background: '#02B577', // Slightly darkened green for hover
                  boxShadow: '0px 4px 10px rgba(3, 203, 136, 0.2)', // A subtle shadow
                  transform: 'scale(1.02)', // Slightly scale up for hover
                }}
                _focus={{
                  boxShadow: '0px 0px 0px 2px rgba(3, 203, 136, 0.4)', // Outline for focus
                  background: '#03CB88',
                }}
              >
                Contact Us
              </Button>
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

export default Business;
