import React from 'react';
import { Text, Box, Flex, Link, Button } from '@chakra-ui/react';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';

function MentorProgram() {
  useScrollToTop();

  return (
    <>
      <Box
        px={{ sm: '10%', md: '10%', lg: '15%' }}
        mt={{ sm: '8%', md: '5%', lg: '5%' }}
        height="70%"
        width="100%"
        zIndex="1"
      >
        <PageHeader title="Mentor Program" />
        <Text lineHeight="24px" fontSize="18px" fontWeight="600" mb="10px">
          Discover our Mentor Program The possibility for any brand, startup or
          company to have quality mentors at their service. Do you want to
          digitalize? Allow your communities to access all the information about
          your commitment without complicating your life? Develop new revenue
          streams or simply review your business plan in light of current
          economic challenges? Our mentor program accompanies you according to
          your reality and allows you to access the most virtuous and qualified
          people. People sourced for years, established internationally and
          available when you are. Today, our projects are present on 3
          continents and range from play-to-earn to education, agroforestry,
          real estate and water management. You will have understood, everyone
          can be accompanied as long as your will is to improve the future by
          our side.
        </Text>
        <Flex
          mt="40px"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Link
            href={'https://app.monopole.network/services/mentor-program'}
            width="300px"
            height="64px"
            isExternal
            target="_blank"
            _focus={{ boxShadow: 'none' }}
          >
            <Button
              borderRadius="16px"
              width="300px"
              height="64px"
              background="#03CB88"
              color="white"
              _active={{
                background: '#8235FF',
              }}
              zIndex="1"
            >
              Beta App
            </Button>
          </Link>
        </Flex>
      </Box>
    </>
  );
}

export default MentorProgram;
