import React from 'react';
import { Text, Box, Flex } from '@chakra-ui/react';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';

function Club() {
  useScrollToTop();

  return (
    <>
      <Box
        px={{ sm: '10%', md: '10%', lg: '15%' }}
        mt={{ sm: '8%', md: '5%', lg: '5%' }}
        height="70%"
        width="100%"
        zIndex="1"
      >
        <PageHeader title="Club" />
        <Text lineHeight="24px" fontSize="18px" fontWeight="600" mb="10px">
          Text for IaaS Club
        </Text>
        <Flex
          mt="40px"
          justifyContent="center"
          alignItems="center"
          width="100%"
        ></Flex>
      </Box>
    </>
  );
}

export default Club;
