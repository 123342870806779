import React from 'react';
import { Text, Box, Flex } from '@chakra-ui/react';
import { Partners as PartnersData } from '../../components/Partners/Partners';
import { Link, Grid, Image } from '@chakra-ui/react';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';

function Partners() {
  useScrollToTop();

  return (
    <>
      <Box
        px={{ sm: '10%', md: '10%', lg: '15%' }}
        mt={{ sm: '8%', md: '5%', lg: '5%' }}
        height="70%"
        width="100%"
        zIndex="1"
      >
        <PageHeader title="Our Partners" />

        <Text lineHeight="24px" fontSize="18px" fontWeight="600" mb="10px">
          Text for Partners
        </Text>
        <Flex
          flexDirection="column"
          width="100%"
          mt="50px"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            w="100%"
            maxW={{ sm: '90%', md: '80%', lg: '70%' }}
            templateColumns={{
              sm: '1fr 1fr',
              md: '1fr 1fr 1fr 1fr',
              lg: '1fr 1fr 1fr 1fr',
            }}
            gap={{ sm: '20px', md: '30px' }}
            justifyContent="center"
            mb="65px"
          >
            {Object.keys(PartnersData).map((id) => {
              return (
                <Box maxW="sm" key={id}>
                  <Flex
                    justifyContent="center"
                    textAlign="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Link href={''} isExternal _focus={{ boxShadow: 'none' }}>
                      <Image
                        borderRadius="full"
                        name={PartnersData[id].name}
                        src={PartnersData[id].img}
                        height="150px"
                        mb="20px"
                      />
                      <Text lineHeight="26px" fontSize="18px" fontWeight="700">
                        {PartnersData[id].name}
                      </Text>
                    </Link>
                  </Flex>
                </Box>
              );
            })}
          </Grid>
        </Flex>
      </Box>
    </>
  );
}

export default Partners;
