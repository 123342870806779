import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import useScrollToTop from '../../hooks/useScrollToTop';
import PageHeader from '../../components/PageHeader';
import CreditsSection from '../../components/CreditsSection';
import background from '../../assets/img/plant_background.png';

const creditsData = {
  brandDesign: [{ name: 'Kristina Gudim', role: 'UX/UI Designer' }],
  landingPage: [
    { name: 'Lucas Sabathé', role: 'Web developper' },
    { name: 'Dylan Nicolle', role: 'Web developper' },
    { name: 'Florian Reumont', role: 'Web developper' },
    { name: 'Lucas Laplanche', role: 'Web developper' },
    { name: 'Valentin Muller', role: 'Web developper' },
    { name: 'William Piron', role: 'Web developper' },
    { name: 'Titouan Marchal', role: 'Web Developer' },
    { name: 'Matthias Monnier', role: 'Web Developer' },
  ],
  dApp: [
    { name: 'Réhane Migan', role: 'Web Developer' },
    { name: 'Yolène Constable', role: 'Web Developer' },
    { name: 'Maria Guy de fontgalland', role: 'Web Developer' },
    { name: 'Aline Hy', role: 'Web Developer' },
    { name: 'Coline Lefebvre', role: 'Web Developer' },
    { name: 'Titouan Marchal', role: 'Web Developer' },
    { name: 'Matthias Monnier', role: 'Web Developer' },
  ],
};

function Credits() {
  useScrollToTop();

  return (
    <Box
      mt={{ sm: '2%', md: '1%', lg: '1%' }}
      maxW="900px"
      mx="auto"
      height="70%"
      width="100%"
      zIndex="1"
      px={4}
      textAlign="justify"
    >
      <PageHeader title="Credits" />
      <Flex direction={{ sm: 'column', md: 'row' }} w="100%" mb="50px">
        <Box
          w={{ md: '50%' }}
          display={{ base: 'none', sm: 'none', md: 'block' }}
        >
          <Image
            src={background}
            width="100%"
            height="auto"
            borderRadius="10px"
          />
        </Box>
        <Box
          w={{ sm: '100%', md: '50%' }}
          textAlign="justify"
          mt={{ sm: '20px', md: '0' }}
          lineHeight="28px"
          fontSize="20px"
          fontWeight="400"
          mb="10px"
          as="ul"
          pl="24px"
        >
          <CreditsSection
            title="Monopole Brand Design"
            credits={creditsData.brandDesign}
          />
          <CreditsSection
            title="Landing Page"
            credits={creditsData.landingPage}
          />
          <CreditsSection title="dApp" credits={creditsData.dApp} />
        </Box>
      </Flex>
    </Box>
  );
}

export default Credits;
