import React from 'react';
import { Flex, Text, useColorMode } from '@chakra-ui/react';

const Stats = () => {
  const { colorMode } = useColorMode();

  return (
    <Flex direction="row" justifyContent="center" alignItems="center">
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
        mr={{ base: 10, md: 0 }}
      >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          mr={{ base: 0, md: 32 }}
        >
          <Text fontSize="2xl" fontWeight="semibold">
            6+
          </Text>
          <Text
            fontSize="lg"
            color={colorMode == 'dark' ? 'whiteAlpha.700' : 'gray.500'}
          >
            Projects
          </Text>
        </Flex>
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          mr={{ base: 0, md: 32 }}
        >
          <Text fontSize="2xl" fontWeight="semibold">
            14K+
          </Text>
          <Text
            fontSize="lg"
            color={colorMode == 'dark' ? 'whiteAlpha.700' : 'gray.500'}
          >
            Followers
          </Text>
        </Flex>
      </Flex>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          mr={{ base: 0, md: 32 }}
        >
          <Text fontSize="2xl" fontWeight="semibold">
            7+
          </Text>
          <Text
            fontSize="lg"
            color={colorMode == 'dark' ? 'whiteAlpha.700' : 'gray.500'}
          >
            Countries
          </Text>
        </Flex>
        <Flex direction="column" justifyContent="center" alignItems="center">
          <Text fontSize="2xl" fontWeight="semibold">
            10+
          </Text>
          <Text
            fontSize="lg"
            color={colorMode == 'dark' ? 'whiteAlpha.700' : 'gray.500'}
          >
            SDGS
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Stats;
