import { Box, useBreakpointValue } from '@chakra-ui/react';

const TAG_STYLES = {
  Charity: {
    borderColor: '#907735',
    bgColor: '#f5fcc5',
    textColor: '#5a4b24',
  },
  Environmental: {
    borderColor: '#316252',
    bgColor: '#9ae6b4',
    textColor: '#1b513f',
  },
  Economic: {
    borderColor: '#3938b6',
    bgColor: '#dbe1fe',
    textColor: '#1b1954',
  },
  Social: {
    borderColor: '#af4444',
    bgColor: '#fee2e2',
    textColor: '#4f1616',
  },
  // Add more tags as needed
};

const SHORTEN_MAP = {
  Environmental: 'Env.',
  Economic: 'Eco.',
};

const Tag = ({ children, shouldTruncate = false }) => {
  const { borderColor, bgColor, textColor } = TAG_STYLES[children] || {};

  const isShorten = useBreakpointValue({ base: true, md: false });

  let displayText = children;
  if (isShorten && shouldTruncate && SHORTEN_MAP[children]) {
    displayText = SHORTEN_MAP[children];
  }

  return (
    <Box
      fontSize={12}
      fontWeight="medium"
      borderRadius="full"
      borderColor={borderColor}
      borderWidth="1px"
      bg={bgColor}
      color={textColor}
      paddingX={{ base: 2, md: 5 }}
      paddingY={1}
    >
      {displayText}
    </Box>
  );
};

export default Tag;
