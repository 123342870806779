import {
  Box,
  Text,
  Heading,
  List,
  ListItem,
  Link,
  Image,
} from '@chakra-ui/react';
import React from 'react';
import SDGGrid from '../../components/SDGGrid';
import globalGoals from '../../assets/img/globalGoals.png';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';

function Charter() {
  useScrollToTop();

  return (
    <>
      <Box
        mt={{ sm: '2%', md: '1%', lg: '1%' }}
        maxW="900px"
        mx="auto"
        height="70%"
        width="100%"
        zIndex="1"
        textAlign="justify"
        px={4}
      >
        <PageHeader title="Charter" />
        <Box lineHeight="24px" fontSize="18px" mb="10px">
          <Heading fontSize="24px" fontWeight="bold" mb="4">
            MONOPOLE Liability Charter Corporate Social Responsibility (CSR)
          </Heading>
          <Text mb="4">
            MONOPOLE is a company that integrates social and environmental
            issues into its business activities and relationships with its
            stakeholders.
          </Text>

          <Text mb="4">
            The approach involves the simultaneous and long-term search for
            objectives related to the three pillars of sustainable development:
          </Text>
          <List mb="4" styleType="disc" pl="6">
            <ListItem>Social</ListItem>
            <ListItem>Environmental</ListItem>
            <ListItem>Economic</ListItem>
          </List>

          <Heading fontSize="20px" fontWeight="bold" mb="4">
            The commitment of Monopole
          </Heading>
          <Text mb="4">
            Monopole/ Velvet Angle, the parent company of the branches under
            construction by Monopole Holding, is the first platform to measure
            impact and make impact projects visible while decentralizing
            opportunities for its users.
          </Text>
          <Text mb="4">
            Its organization, of the DAO type – decentralized autonomous
            organization- was based on values of inclusion, commitment for
            responsible digital, and for equal opportunities.
          </Text>
          <Text mb="4">
            The entire internal and external system is based on values that are
            part of the Label Proof of Impact™ that Monopole will deploy to
            projects for Q1.2023, making Monopole a legitimate protocol to be
            affixed on the Web2/Web3 as on the blockchain, and this on all
            layers wishing to engage openly.
          </Text>

          <Heading fontSize="20px" fontWeight="bold" mb="4">
            Global Goals
          </Heading>
          <Text mb="4">
            In addition to the objectives linked to the three pillars mentioned
            above, Monopole also supports the United Nations’ 17 Global Goals,
            such as:
          </Text>
        </Box>

        <SDGGrid />

        <Box textAlign="justify" mt={10}>
          <Text mt={5}>
            In terms of economic and commercial commitment, Monopole undertakes
            to:
          </Text>
          <Box ml={8}>
            <ul>
              <li>optimize inclusion,</li>
              <li>
                access to technology for people with access to limited
                resources,
              </li>
              <li>promote mutual interests,</li>
              <li>
                maintain legal monitoring and optimize customer satisfaction.
              </li>
            </ul>
          </Box>

          <Text mt={5}>
            In terms of human commitment, Monopole is committed to:
          </Text>
          <Box ml={8}>
            <ul>
              <li>focus on happiness at work,</li>
              <li>ensure the diversity of employees,</li>
              <li>encourage internal innovation and knowledge sharing,</li>
              <li>
                participate in the development of the local economic fabric.
              </li>
            </ul>
          </Box>

          <Text mt={5}>
            Monopole and its signatories are therefore committed and declare
            that they respect:
          </Text>
          <Box ml={8}>
            <ul>
              <li>
                The{' '}
                <Link
                  style={{ color: '#6B30CC' }}
                  href="https://www.un.org/en/about-us/universal-declaration-of-human-rights"
                >
                  Universal Declaration of Human
                </Link>
              </li>
              <li>
                The{' '}
                <Link
                  style={{ color: '#6B30CC' }}
                  href="https://en.wikipedia.org/wiki/European_Convention_on_Human_Rights"
                >
                  Universal Declaration of Human Rights
                </Link>
              </li>
              <li>
                <Link
                  style={{ color: '#6B30CC' }}
                  href="https://www.ilo.org/global/about-the-ilo/how-the-ilo-works/lang--en/index.htm"
                >
                  Principles of the International Labour Organization - ILO
                </Link>
              </li>
              <li>
                <Link
                  style={{ color: '#6B30CC' }}
                  href="https://www.oecd.org/about/"
                >
                  OECD Guidelines for Multinational Enterprises
                </Link>
              </li>
              <li>
                <Link
                  style={{ color: '#6B30CC' }}
                  href="https://unglobalcompact.org/what-is-gc/mission/principles"
                >
                  United Nations Global Compact Principles
                </Link>
              </li>
              <li>
                <Link
                  style={{ color: '#6B30CC' }}
                  href="https://commission.europa.eu/strategy-and-policy/policies/justice-and-fundamental-rights/combatting-discrimination/tackling-discrimination/diversity-and-inclusion-initiatives/diversity-charters-eu-country/portuguese-diversity-charter_en"
                >
                  Portugal's Diversity Charter
                </Link>
              </li>
              <li>
                <Link
                  style={{ color: '#6B30CC' }}
                  href="https://www.businessfornature.org/cop15-business-statement-sign-up"
                >
                  COP15
                </Link>
              </li>
              <li>
                <Link
                  style={{ color: '#6B30CC' }}
                  href="https://cryptoclimate.org/supporters/"
                >
                  CryptoClimate Accord
                </Link>
              </li>
            </ul>
          </Box>
          <Image mx="auto" w={60} h={60} src={globalGoals} alt="Global Goals" />
          <Text
            textAlign="end"
            lineHeight="24px"
            fontSize="18px"
            fontWeight="600"
            mb="10px"
          >
            Monopole
          </Text>
        </Box>
      </Box>
    </>
  );
}

export default Charter;
