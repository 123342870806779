import { Box, Icon, keyframes } from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const ScrollIndicator2 = () => {
  const handleScrollToNextSection = () => {
    const nextSection = document.getElementById('projects');
    const layoutContainer = document.querySelector('.layoutContainer');

    if (nextSection && layoutContainer) {
      const topPosition =
        nextSection.getBoundingClientRect().top + layoutContainer.scrollTop;

      layoutContainer.scrollTo({
        top: topPosition - 95,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box
      zIndex={1000}
      position="absolute"
      bottom="1%"
      left="50%"
      transform="translateX(-50%)"
      cursor="pointer"
      animation={`${bounce} 2s infinite`}
      onClick={handleScrollToNextSection}
    >
      <Icon as={FaChevronDown} boxSize="32px" color="#8235FF" />
    </Box>
  );
};

export default ScrollIndicator2;
