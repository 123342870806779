import React from 'react';
import { Flex, Link, Text, Image, Box } from '@chakra-ui/react';
import { AiFillFilePdf } from 'react-icons/ai';
import { SiCanva } from 'react-icons/si';
import background from '../../assets/img/plant_background.png';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';
import FileVerification from '../../components/FileVerification';

function Documents() {
  useScrollToTop();

  return (
    <Box
      mt={{ sm: '2%', md: '1%', lg: '1%' }}
      maxW="900px"
      mx="auto"
      height="70%"
      width="100%"
      zIndex="1"
      px={4}
      mb={10}
    >
      <PageHeader title="Documents" />
      <Flex direction={{ sm: 'column', md: 'row' }} w="100%" mb="50px">
        <Box
          w={{ md: '50%' }}
          display={{ base: 'none', sm: 'none', md: 'block' }}
        >
          <Image
            src={background}
            width="100%"
            height="auto"
            borderRadius="10px"
          />
        </Box>

        <Box
          w={{ sm: '100%', md: '50%' }}
          textAlign="justify"
          mt={{ sm: '20px', md: '0' }}
          lineHeight="28px"
          fontSize="20px"
          fontWeight="400"
          mb="10px"
          as="ul"
          pl="24px"
        >
          <li style={{ marginBottom: '10px' }}>
            <Flex direction={{ sm: 'column', md: 'row' }} gap={2}>
              <Text fontWeight={700}>Pitch Deck: </Text>
              <Link
                href="https://www.canva.com/design/DAFbgn-wX9Y/TC3eSJ3eD59iseIwd6-sUw/view?utm_content=DAFbgn-wX9Y&utm_campaign=share_your_design&utm_medium=link&utm_source=shareyourdesignpanel%20l"
                color="cornflowerblue"
                isExternal
              >
                <Flex justify="center" align="center" gap={2}>
                  <Text>link</Text>
                  <SiCanva />
                </Flex>
              </Link>
            </Flex>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Flex direction={{ sm: 'column', md: 'row' }} gap={2}>
              <Text fontWeight={700}>Liability Charter:</Text>

              <Link
                href="https://oivsohzqkyisabcspkgr.supabase.co/storage/v1/object/public/landing_docs/liability_charter.pdf?t=2023-09-20T07%3A30%3A40.467Z"
                color="cornflowerblue"
                isExternal
              >
                <Flex justify="center" align="center" gap={2}>
                  <Text>link</Text>
                  <AiFillFilePdf />
                </Flex>
              </Link>
            </Flex>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Flex direction={{ sm: 'column', md: 'row' }} gap={2}>
              <Text fontWeight={700}>
                Work Home Methodologic Book from Monopole:
              </Text>

              <Link
                href="https://oivsohzqkyisabcspkgr.supabase.co/storage/v1/object/public/landing_docs/work_home_metodologic_book_from_monopole.pdf?t=2023-09-20T07%3A31%3A45.595Z"
                color="cornflowerblue"
                isExternal
              >
                <Flex justify="center" align="center" gap={2}>
                  <Text>link</Text>
                  <AiFillFilePdf />
                </Flex>
              </Link>
            </Flex>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Flex direction={{ sm: 'column', md: 'row' }} gap={2}>
              <Text fontWeight={700}>
                Whitepaper:
              </Text>

              <Link
                href="https://www.canva.com/design/DAFsW691A6g/RiaTFGsR3s0aLN5VwZVBQA/edit?utm_content=DAFsW691A6g&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                color="cornflowerblue"
                isExternal
              >
                <Flex justify="center" align="center" gap={2}>
                  <Text>link</Text>
                  <AiFillFilePdf />
                </Flex>
              </Link>
            </Flex>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Flex direction={{ sm: 'column', md: 'row' }} gap={2}>
              <Text fontWeight={700}>
                Global AI Ethics Policy Statement
              </Text>

              <Link
                href="https://www.canva.com/design/DAF6ylafDdo/U6o9gcybibUe2mTf7Bv-fQ/edit?utm_content=DAF6ylafDdo&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                color="cornflowerblue"
                isExternal
              >
                <Flex justify="center" align="center" gap={2}>
                  <Text>link</Text>
                  <AiFillFilePdf />
                </Flex>
              </Link>
            </Flex>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Flex direction={{ sm: 'column', md: 'row' }} gap={2}>
              <Text fontWeight={700}>
                Corporate ESG Policy Statement
              </Text>

              <Link
                href="https://www.canva.com/design/DAF6y13UP4w/2aWKtfEbChIl6j0BeCCaNw/edit?utm_content=DAF6y13UP4w&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                color="cornflowerblue"
                isExternal
              >
                <Flex justify="center" align="center" gap={2}>
                  <Text>link</Text>
                  <AiFillFilePdf />
                </Flex>
              </Link>
            </Flex>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Flex direction={{ sm: 'column', md: 'row' }} gap={2}>
              <Text fontWeight={700}>
                Functional Policy Statement: Global Data Subject Rights
              </Text>

              <Link
                href="https://www.canva.com/design/DAF6y_boqXk/Qh9KlPO-PpYIH6khlvfUzA/edit?utm_content=DAF6y_boqXk&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                color="cornflowerblue"
                isExternal
              >
                <Flex justify="center" align="center" gap={2}>
                  <Text>link</Text>
                  <AiFillFilePdf />
                </Flex>
              </Link>
            </Flex>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Flex direction={{ sm: 'column', md: 'row' }} gap={2}>
              <Text fontWeight={700}>
                Business & Partner Ethics Policy Statement
              </Text>

              <Link
                href="https://www.canva.com/design/DAF6ysn2Xto/-UXJUzv3k7uTBMh0lSG5hQ/edit?utm_content=DAF6ysn2Xto&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                color="cornflowerblue"
                isExternal
              >
                <Flex justify="center" align="center" gap={2}>
                  <Text>link</Text>
                  <AiFillFilePdf />
                </Flex>
              </Link>
            </Flex>
          </li>
        </Box>
      </Flex>
      {/*<PageHeader title="Document Verification" />
      <FileVerification />
      <Divider mt={10} />
      <Flex direction="column" w="100%" mb="50px">
        <PageHeader title="Document Verification" />
        <iframe
          src={'https://services.keeex.me/verify/iframe.html'}
          style={{
            width: '100%',
            height: '660px',
            border: 'none',
          }}
          allow="autoplay; encrypted-media"
          allowFullScreen
        />
        </Flex>*/}
    </Box>
  );
}

export default Documents;
