import React from 'react';
import { Text, Box, Image, Flex } from '@chakra-ui/react';
import background from '../../assets/img/plant_background.png';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';

function AboutUs() {
  useScrollToTop();

  return (
    <Box
      mt={{ sm: '2%', md: '1%', lg: '1%' }}
      maxW="900px"
      mx="auto"
      height="70%"
      width="100%"
      zIndex="1"
      textAlign="justify"
      px={4}
    >
      <PageHeader title="About Us" />
      <Flex direction={{ sm: 'column', md: 'row' }} w="100%" mb="50px">
        <Box
          w={{ md: '50%' }}
          display={{ base: 'none', sm: 'none', md: 'block' }}
        >
          <Image
            src={background}
            width="100%"
            height="auto"
            borderRadius="10px"
          />
        </Box>
        <Box
          w={{ sm: '100%', md: '50%' }}
          textAlign="justify"
          mt={{ sm: '20px', md: '0' }}
        >
          <Text lineHeight="24px" fontSize="20px" fontWeight="400" mb="25px">
            Founded in <b>2021</b> in <b>Lisbon</b>, Monopole aims to secure all
            stakeholders involved in <b>social</b>, <b>environmental</b> and{' '}
            <b>economic engagement</b>.
          </Text>
          <Text lineHeight="24px" fontSize="20px" fontWeight="400" mb="25px">
            Starting from the observation that the so-called{' '}
            <b>"impact" companies</b> lacked <b>visibility</b> and{' '}
            <b>legitimacy</b> in the traditional financial world. However, the
            climate and social emergency is very real. Every day decisions are
            made and they could enable us to achieve this limitation of damage.
            This is why, <b>Monopole</b> is the platform referencing any{' '}
            <b>initiative</b>, whether it is <b>citizen</b>, <b>collective</b>{' '}
            or <b>entrepreneurial</b> and that can improve our future. For a
            decrease in overconsumption in favor of sustainable growth of the
            environment of each.
          </Text>
          <Text lineHeight="24px" fontSize="20px" fontWeight="400" mb="25px">
            <b>Monopole</b> helps <b>companies</b> to:
          </Text>
          <Text
            lineHeight="28px"
            fontSize="20px"
            fontWeight="400"
            mb="10px"
            mt="20px"
            as="ul"
            pl="24px"
          >
            <li style={{ marginBottom: '10px' }}>Proving their impact</li>
            <li style={{ marginBottom: '10px' }}>
              Seek <b>community support</b>
            </li>
            <li style={{ marginBottom: '10px' }}>
              Find <b>potential customers</b>
            </li>
            <li style={{ marginBottom: '10px' }}>
              Monitor the project <b>autonomously</b>
            </li>
            <li style={{ marginBottom: '10px' }}>
              Duplicate promising concepts
            </li>
            <li style={{ marginBottom: '10px' }}>
              Help them <b>finance</b> through tokenisation
            </li>
          </Text>
          <Text
            lineHeight="24px"
            fontSize="20px"
            fontWeight="400"
            mb="25px"
            mt="30px"
          >
            <b>Monopole</b> wants to support <b>communities</b> and{' '}
            <b>investors</b>:
          </Text>
          <Text
            lineHeight="28px"
            fontSize="20px"
            fontWeight="400"
            mb="10px"
            mt="20px"
            as="ul"
            pl="24px"
          >
            <li style={{ marginBottom: '10px' }}>
              In their choice of investment by giving <b>full transparency</b>{' '}
              on projects
            </li>
            <li style={{ marginBottom: '10px' }}>
              By auditing and accompanying the projects they support
            </li>
            <li style={{ marginBottom: '10px' }}>
              Securing them by popularizing risk management and making it
              accessible
            </li>
            <li style={{ marginBottom: '10px' }}>
              Making opportunities independently accessible through{' '}
              <b>tokenisation</b> and <b>risk aggregation</b>
            </li>
          </Text>
          <Text
            lineHeight="24px"
            fontSize="20px"
            fontWeight="400"
            mb="25px"
            mt="30px"
          >
            <b>Monopole</b> wants to help <b>institutions</b>:
          </Text>
          <Text
            lineHeight="28px"
            fontSize="20px"
            fontWeight="400"
            mb="50px"
            mt="20px"
            as="ul"
            pl="24px"
          >
            <li style={{ marginBottom: '10px' }}>
              Source <b>territorial projects</b>
            </li>
            <li style={{ marginBottom: '10px' }}>
              Emergency level by referencing solutions
            </li>
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}

export default AboutUs;
