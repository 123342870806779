import React from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { LocationIcon, EarthIcon } from '../../components/Icons/Icons';
import ScrollIndicator2 from '../../components/ScrollIndicator2';
import Stats from '../../components/Landing/Stats';
import Tag from '../../components/Landing/Tag';
import ProjectCard from '../../components/Landing/ProjectCard';
import ValueCard from '../../components/Landing/ValueCard';
import useScrollToTop from '../../hooks/useScrollToTop';

const projects = [
  {
    tags: ['Environmental'],
    title: 'Blueprint for a Sustainable City',
    imgSrc: '/greencity.webp',
    altText: 'Sustainable City',
  },
  {
    tags: ['Charity', 'Social'],
    title: 'Bridging the Education Gap',
    imgSrc: '/edubridge.webp',
    altText: 'Education',
  },
  {
    tags: ['Environmental', 'Economic'],
    title: 'Climate-Resilient Sustainable Farming',
    imgSrc: '/agrirevolution.webp',
    altText: 'Sustainable Farming',
  },
  // Add more projects as needed
];

const Landing = () => {
  useScrollToTop();

  /*const breakpoint = useBreakpointValue({
    base: 'base',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    '2xl': '2xl',
  });*/

  const { colorMode, setColorMode } = useColorMode();

  React.useEffect(() => {
    setColorMode('light');
  }, []);

  return (
    <Box>
      <Flex
        position="relative"
        height="100vh"
        w="100%"
        mt="-95px"
        overflowX="hidden"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Flex
            position="relative"
            flexDirection="column"
            backgroundColor="#8235FF"
            width={{ base: '85%', lg: '90%', xl: '80%', '2xl': '71%' }}
            borderRadius="2xl"
            p={{ base: 7, sm: 14 }}
            mt={{ base: '80px', sm: '100px' }}
            gap={{ base: 4, sm: 4, md: 4, lg: 4, xl: 4, '2xl': 4 }}
          >
            <Text
              fontSize={{ base: 'xl', sm: '3xl', md: '4xl', lg: '5xl' }}
              color="white"
              fontWeight={700}
              width={{ base: '100%', lg: '50%', xl: '50%' }}
              lineHeight="60px"
              flexWrap="wrap"
            >
              Proof of Impact Label
            </Text>
            <Flex
              direction="column"
              gap={3}
              fontSize={{ base: 'sm', sm: 'sm', md: 'md' }}
              width={{ base: '100%', lg: '50%', xl: '50%' }}
            >
              <Text color="white" textAlign="justify">
                The Proof of Impact is a traceability service that assesses the
                impact of projects showcased in the Monopole studio.
              </Text>
              <Text color="white" textAlign="justify">
                The corporate studio required a qualitative and certifying
                filter to gauge, supervise and rate the social, environmental
                and societal dedication of these projects. This is the inaugural
                project to be integrated into the Monopole Studio.
              </Text>
            </Flex>

            <Flex
              direction={{ base: 'column', sm: 'row' }}
              width={{ base: '100%', lg: '50%', xl: '50%' }}
              justifyContent="space-between"
              alignItems={{ base: 'flex-start', sm: 'center' }}
              gap={2}
            >
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                display={{ base: 'none', sm: 'flex' }}
              >
                <Icon as={LocationIcon} color="white" mr={2} boxSize={5} />
                <Text fontSize="md" color="white">
                  International Scope
                </Text>
              </Flex>
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                display={{ base: 'none', sm: 'flex' }}
              >
                <Icon as={EarthIcon} color="white" mr={2} boxSize={5} />
                <Text fontSize="md" color="white">
                  Integrity, Security & Audit
                </Text>
              </Flex>
            </Flex>
            <Button
              as="a"
              href="https://monopole.network/#/services/label"
              target="_blank"
              variant="solid"
              borderRadius="3xl"
              bg="white"
              color="#8235FF"
              borderStyle="solid"
              borderWidth={1}
              borderColor="#8235FF"
              alignSelf={{ base: 'center', sm: 'flex-start' }}
              _hover={{
                background: '#8235FF',
                color: 'white',
                borderColor: 'white',
              }}
            >
              See More
            </Button>
            <Box
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              display={{ base: 'none', lg: 'block' }}
              right="5%"
              width="350px"
              overflow="hidden"
            >
              <Image
                borderRadius="20px"
                src="/poi_landing.png"
                alt="Proof of Impact"
                width="100%"
                objectFit="contain"
              />
            </Box>
          </Flex>

          <Stats />
        </Flex>
        <ScrollIndicator2 />
      </Flex>

      <Flex
        id="projects"
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        bg={colorMode == 'dark' ? '#1d1130' : '#DCC7FF'}
        height={{ base: 'auto', lg: '90vh' }}
        py={{ base: 6, lg: 0 }}
        gap={{ base: 6, lg: 1 }}
      >
        <Flex
          direction="column"
          width={{ base: '90%', md: '80%', lg: '90%', '2xl': '80%' }}
          maxW="1200px"
        >
          <Text textAlign="left" fontSize="3xl" fontWeight="medium" mt={2}>
            Projects
          </Text>
          <Flex
            direction="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="center"
            gap={1}
          >
            <Tag>Charity</Tag>
            <Tag>Environmental</Tag>
            <Tag>Economic</Tag>
            <Tag>Social</Tag>
          </Flex>
        </Flex>

        <Flex
          direction={{ base: 'column', lg: 'row' }}
          width={{ base: '90%', md: '80%', lg: '90%', '2xl': '80%' }}
          maxW="1200px"
          mt={2}
          gap={4}
        >
          {/* big left card */}
          <Flex
            direction="column"
            width={{ base: '100%', lg: '50%' }}
            backgroundColor={colorMode == 'dark' ? 'black' : 'white'}
            borderRadius="2xl"
          >
            <Box
              height={{ base: '20vh', md: '20vh', xl: '30vh' }}
              width="100%"
              overflow="hidden"
              borderTopRadius="2xl"
            >
              <Image
                src="/ecohomes.webp"
                alt="Eco Homes"
                objectFit="cover"
                objectPosition="center"
                width="100%"
                height="100%"
              />
            </Box>
            <Flex m={6} direction="column">
              <Flex>
                <Tag>Environmental</Tag>
              </Flex>

              <Text fontSize={{ base: '2xl', md: '3xl' }} fontWeight="medium">
                Eco Homes
              </Text>
              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                color="gray.500"
                textAlign="justify"
              >
                This initiative is dedicated to encouraging the use of
                energy-efficient appliances, endorsing green construction
                practices, and facilitating the adoption of renewable energy
                sources such as solar and wind power.
              </Text>
              <Flex
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  variant="ghost"
                  fontWeight="normal"
                  as="a"
                  href="https://app.testnet.monopole.network/project/erd1qqqqqqqqqqqqqpgq9v669w3z4gmstqnux2p7mg8wdw7hnx8h9x2su268uq"
                  target="_blank"
                  _hover={{ color: '#8235FF' }}
                >
                  <Icon
                    as={ArrowForwardIcon}
                    color="#8235FF"
                    mr={2}
                    boxSize={6}
                  />
                  Read More
                </Button>
              </Flex>
            </Flex>
          </Flex>
          {/* 3 small right cards */}
          <Flex
            direction="column"
            width={{ base: '100%', lg: '50%' }}
            justifyContent="space-between"
            gap={4}
          >
            {projects.map((project, index) => {
              //if (breakpoint === 'md' && index > 1) return null;
              return <ProjectCard key={index} {...project} />;
            })}
          </Flex>
        </Flex>

        <Button
          as="a"
          target="_blank"
          href="https://app.testnet.monopole.network/"
          variant="solid"
          fontWeight="medium"
          borderRadius="full"
          borderWidth={1}
          borderColor="#8235FF"
          bg="white"
          color="#8235FF"
          _hover={{ background: '#8235FF', color: 'white' }}
          my={2}
        >
          Discover Our Portfolio
        </Button>
      </Flex>

      <Flex direction="column" justifyContent="center" alignItems="center">
        <Text
          fontSize="3xl"
          fontWeight="medium"
          mt={{ base: 50, lg: 30 }}
          mb={{ base: 0, lg: 30 }}
        >
          Our Values
        </Text>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justifyContent="space-between"
          alignItems={{ base: 'center', lg: 'flex-start' }}
          width={{ base: '90%', md: '80%', lg: '90%', '2xl': '80%' }}
          maxW="1200px"
        >
          <ValueCard
            title="Ethics"
            description="The entire Monopole ecosystem, both internal and external, is
            devoted to upholding social, ecological, and economic standards.
            Associations receive no less than 15% of platform fees."
            icon="/family.svg"
            alt="Family"
          />
          <ValueCard
            title="Legal Conformity"
            description="One of Monopole's primary goals is to ensure that all project and
            community activities are highlighted. As a result, legality is our
            top priority in every area in which we operate."
            icon="/sharing.svg"
            alt="People sharing knowledge"
          />
          <ValueCard
            title="Security"
            description="Safety is our primary concern. Whether institutional, project
              leaders, or communities, our objective is to enhance security for
              every action taken by our clients, leaving them to focus solely on
              selecting the appropriate support and assessing its feasibility."
            icon="/security.svg"
            alt="Encrypted phone"
          />
        </Flex>
        {/* Purple rectangle */}
        <Flex
          direction={{ base: 'column', md: 'row' }}
          width={{ base: '90%', md: '80%', lg: '90%', '2xl': '80%' }}
          maxW="1200px"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#8235FF"
          borderRadius="2xl"
          p={{ base: 10, md: 30 }}
          mt={{ base: 70, md: 70 }}
          mb={50}
        >
          <Image src="/world.svg" alt="World" width="250px" />
          <Flex direction="column" ml={{ base: 0, md: 12 }}>
            <Text fontSize="2xl" color="white" fontWeight="medium" mt={2}>
              Do you want to join us?
            </Text>
            <Text textAlign="justify" fontSize="md" color="white" mt={2}>
              Are you a start-up, brand, or local authority looking to showcase
              your social, environmental, and economic dedication? Do you aspire
              to secure funding and support to advance your brand in crucial
              aspects such as product strategy, gamification, and business
              development while remaining true to your values and message?
            </Text>
            <Text textAlign="justify" fontSize="md" color="white" mt={2}>
              Embrace the Proof-of-Impact movement and join us in monopolising
              the World.
            </Text>
            <Button
              as="a"
              target="_blank"
              href="https://app.testnet.monopole.network/dashboard"
              width="200px"
              variant="solid"
              alignSelf={{ base: 'center', md: 'flex-start' }}
              fontWeight="medium"
              borderRadius="full"
              borderWidth={1}
              borderColor="#8235FF"
              bg="white"
              color="#8235FF"
              mt={7}
              _hover={{
                background: '#8235FF',
                color: 'white',
                borderColor: 'white',
              }}
            >
              Flip The Script
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Landing;
