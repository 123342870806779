import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';
import '@fontsource/dm-sans/400.css';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/700.css';

import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useColorMode, Box, Flex } from '@chakra-ui/react';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import Landing from '../views/Landing/Landing.js';
import AboutUs from '../views/Pages/AboutUs.js';
import Business from '../views/Pages/Business.js';
import Charter from '../views/Pages/Charter.js';
import Club from '../views/Pages/Club.js';
import CodeOfConduct from '../views/Pages/CodeOfConduct';
import DocumentsManagement from '../views/Pages/DocumentsManagement.js';
import Documents from '../views/Pages/Documents.js';
import Foundation from '../views/Pages/Foundation.js';
import Label from '../views/Pages/Label.js';
import Legal from '../views/Pages/Legal.js';
import MentorProgram from '../views/Pages/MentorProgram.js';
import Partners from '../views/Pages/Partners.js';
import PrivacyPolicy from '../views/Pages/PrivacyPolicy.js';
import TermsOfUse from '../views/Pages/TermsOfUse.js';
import TeamPage from '../views/Pages/Team.js';
import RoadmapPage from '../views/Pages/Roadmap.js';
import Credits from '../views/Pages/Credits';
import Verif from '../views/Pages/Verif';

export default function Layout() {
  const { colorMode } = useColorMode();

  return (
    <Box>
      <Box
        position="absolute"
        top={0}
        left={0}
        zIndex={-1}
        w="100vw"
        h="100vh"
        background={
          colorMode === 'dark'
            ? 'radial-gradient(circle, rgba(229,70,252,0.30) 0%, rgba(63,94,251,0) 80%)'
            : 'radial-gradient(circle, rgba(245, 247, 255, 0) 0%, rgba(230, 230, 255, 0) 80%)'
        }
      />
      <Navbar />
      <Flex
        position="absolute"
        top="0"
        left="0"
        width="100%"
        h="100%"
        bottom="0"
        overflow="auto"
        direction="column"
        justify="space-between"
        className="layoutContainer"
        id="layout"
      >
        <Box mt="95px" w="100%">
          <Routes>
            <Route path="/" element={<Landing />} />
            {/*<Route path="/landing" element={<Landing />} />*/}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/code-of-conduct" element={<CodeOfConduct />} />
            <Route path="/documents-management" element={<DocumentsManagement />} />
            {/*<Route path="/team" element={<TeamPage />} />*/}
            <Route path="/roadmap" element={<RoadmapPage />} />
            <Route path="/charter" element={<Charter />} />
            <Route path="/club" element={<Club />} />
            <Route path="/foundation" element={<Foundation />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/credits" element={<Credits />} />
            <Route path="/adlqsknbdiqsucboqsbdoj" element={<Verif />} />

            <Route
              path="/services/mentor-program"
              element={<MentorProgram />}
            />
            <Route path="/services/label" element={<Label />} />
            <Route path="/services/business" element={<Business />} />
          </Routes>
        </Box>
        <Footer />
      </Flex>
    </Box>
  );
}
