import { useColorMode, useColorModeValue } from '@chakra-ui/react';

const useThemeColors = () => {
  const { colorMode } = useColorMode();
  const alpha50 = useColorModeValue(
    'rgba(0, 0, 0, 0.04)',
    'rgba(255, 255, 255, 0.04)'
  );
  const alpha100 = useColorModeValue(
    'rgba(0, 0, 0, 0.06)',
    'rgba(255, 255, 255, 0.06)'
  );
  const alpha200 = useColorModeValue(
    'rgba(0, 0, 0, 0.08)',
    'rgba(255, 255, 255, 0.08)'
  );
  const alpha300 = useColorModeValue(
    'rgba(0, 0, 0, 0.16)',
    'rgba(255, 255, 255, 0.16)'
  );
  const alpha400 = useColorModeValue(
    'rgba(0, 0, 0, 0.24)',
    'rgba(255, 255, 255, 0.24)'
  );
  const alpha500 = useColorModeValue(
    'rgba(0, 0, 0, 0.36)',
    'rgba(255, 255, 255, 0.36)'
  );
  const alpha600 = useColorModeValue(
    'rgba(0, 0, 0, 0.48)',
    'rgba(255, 255, 255, 0.48)'
  );
  const alpha700 = useColorModeValue(
    'rgba(0, 0, 0, 0.64)',
    'rgba(255, 255, 255, 0.64)'
  );
  const alpha800 = useColorModeValue(
    'rgba(0, 0, 0, 0.80)',
    'rgba(255, 255, 255, 0.80)'
  );
  const alpha900 = useColorModeValue(
    'rgba(0, 0, 0, 0.92)',
    'rgba(255, 255, 255, 0.92)'
  );

  const modalBg = useColorModeValue(
    'hsl(300, 20.0%, 99.0%)',
    'hsl(230deg 11.63% 8.43%)'
  );

  const menuColor = useColorModeValue('white', '#000126');
  const accountColor = useColorModeValue('blackAlpha.600', 'whiteAlpha.600');
  const textColor = useColorModeValue('blackAlpha.800', 'whiteAlpha.800');
  const textLightColor = useColorModeValue('blackAlpha.700', 'whiteAlpha.700');
  const textColorStyle = useColorModeValue(
    'rgba(0, 0, 0, 0.80)',
    'rgba(255, 255, 255, 0.80)'
  );
  const titleColor = useColorModeValue('blackAlpha.900', 'whiteAlpha.900');
  const lightColor = useColorModeValue('blackAlpha.500', 'whiteAlpha.500');
  const borderColor = useColorModeValue('blackAlpha.300', 'whiteAlpha.300');

  const backgroundColor = useColorModeValue('whiteAlpha.100', 'whiteAlpha.100');

  return {
    colorMode,
    menuColor,
    accountColor,
    textColor,
    textLightColor,
    textColorStyle,
    titleColor,
    lightColor,
    borderColor,
    backgroundColor,
    alpha50,
    alpha100,
    alpha200,
    alpha300,
    alpha400,
    alpha500,
    alpha600,
    alpha700,
    alpha800,
    alpha900,
    modalBg,
  };
};

export default useThemeColors;
