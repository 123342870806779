import { Grid, Flex, Text, Image, Box } from '@chakra-ui/react';

import gif01 from '../../assets/img/sdgs/sdg01.gif';
import gif02 from '../../assets/img/sdgs/sdg02.gif';
import gif03 from '../../assets/img/sdgs/sdg03.gif';
import gif04 from '../../assets/img/sdgs/sdg04.gif';
import gif05 from '../../assets/img/sdgs/sdg05.gif';
import gif06 from '../../assets/img/sdgs/sdg06.gif';
import gif07 from '../../assets/img/sdgs/sdg07.gif';
import gif08 from '../../assets/img/sdgs/sdg08.gif';
import gif09 from '../../assets/img/sdgs/sdg09.gif';
import gif10 from '../../assets/img/sdgs/sdg10.gif';
import gif11 from '../../assets/img/sdgs/sdg11.gif';
import gif12 from '../../assets/img/sdgs/sdg12.gif';
import gif13 from '../../assets/img/sdgs/sdg13.gif';
import gif14 from '../../assets/img/sdgs/sdg14.gif';
import gif15 from '../../assets/img/sdgs/sdg15.gif';
import gif16 from '../../assets/img/sdgs/sdg16.gif';
import gif17 from '../../assets/img/sdgs/sdg17.gif';

function SDGGrid() {
  return (
    <Grid
      templateColumns={{
        sm: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        lg: 'repeat(1, 1fr)',
        xl: 'repeat(1, 1fr)',
      }}
      gap={6}
    >
      {[
        {
          src: gif01,
          title: '1 - No Poverty',
          text: (
            <Flex direction="column" gap={2}>
              <Text>Anticipation of constraints and risk prevention.</Text>
              <Text>
                To ensure a significant mobilization of resources from multiple
                sources, including through enhanced development cooperation, in
                order to provide developing countries, in particular least
                developed countries, adequate and predictable means of
                implementing programmes and policies to end poverty in all its
                forms.
              </Text>
            </Flex>
          ),
        },
        {
          src: gif02,
          title: '2 - Zero Hunger',
          text: (
            <Flex direction="column" gap={2}>
              <Text>
                Reducing costs related to resource consumption or waste
                generation.
              </Text>
              <Text>
                Increase investment in rural infrastructure, particularly in the
                context of strengthening international cooperation, agricultural
                research and extension services and the development of plant and
                livestock technologies and gene banks to strengthen the
                agricultural productive capacities of developing countries,
                particularly least developed countries.
              </Text>
              <Text>
                Deploy food programs linked to our events to ensure fair food
                management while avoiding losses by providing access to local
                entities with surplus.
              </Text>
              <Text>
                The consumption of food in Monopole's premises will always be
                linked to local and committed agriculture.
              </Text>
            </Flex>
          ),
        },
        {
          src: gif03,
          title: '3 - Good Health and Well-being',
          text: (
            <Flex direction="column" gap={2}>
              <Text>
                By 2030, reduce by one third, through prevention and treatment,
                the rate of premature mortality from non-communicable diseases
                and promote mental health and well-being.
              </Text>
              <Text>
                Strengthen prevention and treatment of substance abuse,
                including narcotics and alcohol.
              </Text>
              <Text>
                Every employee will receive universal health coverage, including
                protection against financial risks and access to quality
                essential health services.
              </Text>
            </Flex>
          ),
        },
        {
          src: gif04,
          title: '4 - Quality Education',
          text: (
            <Flex direction="column" gap={2}>
              By 2030, ensure that all those interested acquire the knowledge
              and skills necessary to promote sustainable development, including
              through education for sustainable development and lifestyles,
              human rights, gender equality, promotion of a culture of peace and
              non-violence, global citizenship and appreciation of cultural
              diversity and the contribution of culture to sustainable
              development.
            </Flex>
          ),
        },
        {
          src: gif05,
          title: '5 - Gender Equality',
          text: (
            <Flex direction="column" gap={2}>
              <Text>
                Establish a platform for women to obtain equal rights to
                economic resources, as well as access to ownership and control
                of land and other forms of property, financial services,
                heritage and natural resources, in compliance with national law.
              </Text>
              <Text>
                Ensuring the full and effective participation of women and their
                equal access to leadership at all levels of decision-making, in
                political, economic and public life.
              </Text>
              <Text>
                The internal reinforcement of pride in difference and the
                optimization of HR processes in order to be as fair as possible
                on equal opportunities and gender.
              </Text>
              <Text>
                Strengthen the use of key technologies, in particular IT and
                communications, to promote women's empowerment.
              </Text>
            </Flex>
          ),
        },
        {
          src: gif06,
          title: '6 - Clean Water and Sanitation',
          text: (
            <Flex direction="column" gap={2}>
              Protect and restore water-related ecosystems, including mountains,
              forests, wetlands, rivers, aquifers and lakes.
            </Flex>
          ),
        },
        {
          src: gif07,
          title: '7 - Affordable and Clean Energy',
          text: (
            <Flex direction="column" gap={2}>
              <Text>
                Significantly increase the share of renewable energy in the
                global energy mix.
              </Text>
              <Text>
                Strengthen international cooperation to facilitate access to
                clean energy research and technologies, including renewable
                energy, energy efficiency and new clean fossil fuel
                technologies; and promote investment in energy infrastructure
                and clean energy technologies.
              </Text>
            </Flex>
          ),
        },
        {
          src: gif08,
          title: '8 - Decent Work and Economic Growth',
          text: (
            <Flex direction="column" gap={2}>
              <Text>
                Achieve a high level of economic productivity through
                diversification, technological modernisation and innovation, in
                particular by focusing on high value added and labour intensive
                sectors.
              </Text>
              <Text>
                Develop a platform that fosters productive activities, the
                creation of decent jobs, entrepreneurship, creativity and
                innovation and stimulate the growth of micro and small and
                medium-sized enterprises and facilitate their international
                deployment.
              </Text>
              <Text>
                Develop and implement services to develop sustainable tourism
                that creates jobs and enhances local culture and products.
              </Text>
              <Text>
                Ensure the coordination and implementation of sustainable
                development policies (impetus, information and communication,
                internal and external monitoring, coordination of transversal
                projects, monitoring of reporting actions, etc.).
              </Text>
              <Text>
                Implement a Corporate Social Responsibility - CSR approach
                within the companies and subsidiaries of the group: drafting of
                an ethical charter, raising employees' awareness of the
                principles set out in it and checking its proper application.
              </Text>
            </Flex>
          ),
        },
        {
          src: gif09,
          title: '9 - Industry, Innovation, and Infrastructure',
          text: (
            <Flex direction="column" gap={2}>
              Create a resilient and accessible blockchain infrastructure at a
              lower cost focused on deploying scalable and sustainable
              innovations or solutions.
            </Flex>
          ),
        },
        {
          src: gif10,
          title: '10 - Reduced Inequality',
          text: (
            <Flex direction="column" gap={2}>
              <Text>
                Reduce transaction costs of remittances below 3 per cent and
                eliminate cash transfer corridors with costs above 5%.
              </Text>
              <Text>
                Position oneself positively in the face of future regulations
                and help to improve the understanding of the ecosystem for a
                better economy.
              </Text>
            </Flex>
          ),
        },
        {
          src: gif11,
          title: '11 - Sustainable Cities and Communities',
          text: (
            <Flex direction="column" gap={2}>
              <Text>
                Strengthen efforts to protect and preserve the world's cultural
                and natural heritage.
              </Text>
              <Text>
                Strengthen inclusive and sustainable urbanization for all and
                capacity for participatory, integrated and sustainable planning
                and management of human settlements in all countries.
              </Text>
              <Text>
                Reduce negative environmental impact with special attention to
                air quality and waste management.
              </Text>
            </Flex>
          ),
        },
        {
          src: gif12,
          title: '12 - Responsible Consumption and Production',
          text: (
            <Flex direction="column" gap={2}>
              <Text>
                Ensure that all people have the necessary training and knowledge
                for sustainable development and a lifestyle in harmony with
                their environment.
              </Text>
              <Text>
                Develop and use tools to control impacts on sustainable
                development.
              </Text>
            </Flex>
          ),
        },
        {
          src: gif13,
          title: '13 - Climate Action',
          text: (
            <Flex direction="column" gap={2}>
              Incorporate climate change measures into internal strategies and
              allow each employee to work from where they want.
            </Flex>
          ),
        },
        {
          src: gif14,
          title: '14 - Life Below Water',
          text: (
            <Flex direction="column" gap={2}>
              To improve the conservation of oceans and their resources in a
              more sustainable manner in accordance with the provisions of
              international law set out in the United Nations Convention on the
              Law of the Sea, which provides the legal framework for the
              conservation and sustainable use of oceans and their resources, as
              recalled in paragraph 158 of “The Future We Want”.
            </Flex>
          ),
        },
        {
          src: gif15,
          title: '15 - Life on Land',
          text: (
            <Flex direction="column" gap={2}>
              <Text>
                Undertake that the real estate resulting from the activity of
                the Studio does not encroach in any way on a terrestrial space
                that could cause a disruption, massive deforestation or other
                disturbance that could create desertification or degraded soil.
                Give priority to renovation.
              </Text>
              <Text>
                Promote sustainable management of all types of forest, halt
                deforestation, restore degraded forests and significantly
                increase afforestation and reforestation.
              </Text>
              <Text>
                Combat desertification, restore degraded land and soils,
                including land affected by desertification, drought and
                flooding, and strive to achieve a land degradation neutral
                world.
              </Text>
              <Text>
                Mobilize financial resources from all sources and significantly
                increase them to preserve biodiversity and ecosystems and
                exploit them sustainably.
              </Text>
            </Flex>
          ),
        },
        {
          src: gif16,
          title: '16 - Peace, Justice, and Strong Institutions',
          text: (
            <Flex direction="column" gap={2}>
              <Text>
                Through the Studio, the Foundation or any other signatory actor,
                Monopole is committed against abuse, exploitation and
                trafficking, and all forms of violence and torture against
                children.
              </Text>
              <Text>
                Through our internal due diligence but also through our
                KYC/KYT/KYB partners, Monopole is committed to significantly
                reducing illicit financial flows and arms trafficking, fighting
                all forms of organised crime.
              </Text>
              <Text>
                Through our development department, Monopole is committed to
                strengthening the recovery and restitution activities of stolen
                goods on the blockchain.
              </Text>
              <Text>
                Through blockchain technology, Monopole enables effective,
                accountable and transparent institutions at all levels.
              </Text>
              <Text>
                Through DAO and S-DAPP, ensure that dynamism, openness,
                participation and representation at all levels characterize
                decision-making.
              </Text>
            </Flex>
          ),
        },
        {
          src: gif17,
          title: '17 - Partnerships for the Goals',
          text: (
            <Flex direction="column" gap={2}>
              <Text>
                Monopoly is based on consolidating strong and sustainable global
                partnerships.
              </Text>
              <Text>
                Adopt and implement measures to encourage investment in
                least-developed countries while providing optimised solutions so
                that they do not generate more climate or land damage.
              </Text>
              <Text>
                To promote the development, transfer and dissemination of
                environmentally friendly technologies for the benefit of
                communities, emerging areas and all those wishing to benefit
                from them.
              </Text>
              <Text>
                Strengthen the Global Partnership for Sustainable Development,
                coupled with multi-stakeholder partnerships to mobilize and
                share knowledge, expertise, technology and financial resources,
                to help all countries, to achieve the Sustainable Development
                Goals.
              </Text>
              <Text>
                Monopole wants to be an agnostic, neutral organization and
                intended to promote solutions available internationally, thus
                allowing a consolidation and optimization of performance and
                efforts related to global and international issues.
              </Text>
              <Text>
                Each entity resulting from Monopole will automatically be
                signatory to the code of conduct as well as to this charter of
                activities and commitments.
              </Text>
            </Flex>
          ),
        },
      ].map((sdg, index) => (
        <Flex
          key={index}
          flexDirection="column"
          alignItems="center"
          textAlign="justify"
          gap={2}
          mt={6}
        >
          <Text lineHeight="22px" fontSize="24px" fontWeight="600">
            {sdg.title}
          </Text>
          {!sdg.src.includes('.gif') ? (
            <Image
              src={sdg.src}
              width="60%"
              maxW="400px"
              rounded="10px"
              margin="10px 20px"
            />
          ) : (
            <Image
              src={sdg.src}
              width="60%"
              maxW="400px"
              rounded="10px"
              border="40px solid #082c2c"
              margin="10px 20px"
            />
          )}
          <Box width="100%">{sdg.text}</Box>
        </Flex>
      ))}
    </Grid>
  );
}

export default SDGGrid;
