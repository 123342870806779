import React from 'react';
import { Box, Heading, List, ListItem, Text } from '@chakra-ui/react';

const CreditsSection = ({ title, credits }) => {
  return (
    <Box mb={6}>
      <Heading as="h3" size="lg" mb={3}>
        {title}
      </Heading>
      <List spacing={2}>
        {credits.map((credit, index) => (
          <ListItem key={index}>
            <Text>
              {credit.name} - {credit.role}
            </Text>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CreditsSection;
