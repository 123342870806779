import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { Chrono } from 'react-chrono';
import { Roadmap } from '../../components/Roadmap';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';

function RoadmapPage() {
  useScrollToTop();

  return (
    <Box
      mt={{ sm: '2%', md: '1%', lg: '1%' }}
      maxW="900px"
      mx="auto"
      height="70%"
      width="100%"
      zIndex="1"
      textAlign="justify"
    >
      <Flex flexDirection="column" w="100%">
        <Flex
          zIndex="0"
          justifyContent="center"
          textAlign="center"
          alignItems="center"
          flexDirection="column"
          w="100%"
          id="roadmap-container"
        >
          <PageHeader title="Roadmap" />

          <Flex
            w="100%"
            px={{ sm: '0%', md: '15%', lg: '30%' }}
            mb="1 0px"
            color="black"
            py="5px"
            pt="100px"
            id="roadmap"
            backgroundColor="#F7FAFC"
            borderRadius="16px"
          >
            <Chrono
              items={Roadmap}
              mode="HORIZONTAL"
              borderLessCards={true}
              slideShow={true}
              slideItemDuration={3000}
              lineWidth="4"
              cardWidth="600"
              theme={{
                primary: '#03CB88',
                secondary: '#03CB88',
                titleColor: 'black',
                color: 'black',
                cardBgColor: 'transparent',
              }}
            >
              {Object.keys(Roadmap).map((id) => (
                <Text
                  key={id}
                  mb="10px"
                  fontSize="16px"
                  fontWeight="700"
                  zIndex="1"
                  flexDirection="center"
                  align="center"
                  backgroundColor="transparent"
                >
                  {Roadmap[id].text.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Text>
              ))}
            </Chrono>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default RoadmapPage;
