import React from 'react';
import { Text } from '@chakra-ui/react';
import useScrollToTop from '../../hooks/useScrollToTop';

function Legal() {
  useScrollToTop();

  return (
    <>
      <Text>Legal</Text>
    </>
  );
}

export default Legal;
