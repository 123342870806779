import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import theme from './theme/theme.js';
import Layout from './layouts/Layout.js';

const root = document.getElementById('root');
const reactRoot = createRoot(root);

reactRoot.render(
  <HashRouter>
    <ChakraProvider theme={theme} resetCss={true}>
      <Routes>
        <Route path="*" element={<Layout />} />
      </Routes>
    </ChakraProvider>
  </HashRouter>
);
