import { useEffect } from 'react';

const useKeeeXSDK = (onLoadCallback) => {
  useEffect(() => {
    if (window.KXSDK) {
      // If KXSDK is already loaded, execute the callback immediately
      onLoadCallback();
      return;
    }

    const script = document.createElement('script');
    script.src = '/keeex.min.js';
    script.async = true;

    script.onload = () => {
      console.log('KeeeX SDK loaded', window.KXSDK);
      onLoadCallback(); // Execute the callback after the script is loaded
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [onLoadCallback]);
};

export default useKeeeXSDK;
