import { Button } from '@chakra-ui/react';

const JoinTheMoveButton = ({ link }) => {
  return (
    <Button
      as="a"
      target="_blank"
      href={link}
      variant="solid"
      borderRadius="full"
      fontSize={14}
      bg="#8235FF"
      borderStyle="solid"
      borderWidth={1}
      borderColor="#8235FF"
      color="white"
      fontWeight="normal"
      mt={5}
      _hover={{
        background: 'transparent',
        color: '#8235FF',
      }}
    >
      Join the Move
    </Button>
  );
};

export default JoinTheMoveButton;
