const Roadmap = [
  {
    title: "🎩 Q2 2023 🎩",
    text:
      "MultiversX Integration\n Testnet Bounty\n Switzerland HQ\n Qualification Mentor Program Launch\n 1st Projects on Whitelabel"
  },
  {
    title: "🎩 Q3 2023 🎩",
    text:
      "Whitelist ICO Launch\n Beta Launch\n 2 Blockchains+ Development\n Label v1 Launch\n Price of the Impact Project 2023-jury",
  },
  {
    title: "🎩 Q1 2024 🎩",
    text:
      "App Live\n CryptoPartner Testnet\n Studio Launch\n Asia HQ\n WIS 2023\n 1st Monopole Pitch Day Belgium",
  },
  {
    title: "🎩 Q2 2024 🎩",
    text: "1 Blockchain+ Development\n Africa Development\n LatAm Consolidation\n 1st Monopole Pitch Day North Europe",
  },
  {
    title: "🎩 Q3 2024 🎩",
    text: "1 Blockchain+ Development\n CryptoPartner Live\n Africa Consolidation\n Oceania HQ\n 1st Monopole Pitch Day Africa Tour\n 1st Monopole Pitch Day Asia Tour",
  },
];

export { Roadmap };
