import React from 'react';
import { Box } from '@chakra-ui/react';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';
import FileVerification from '../../components/FileVerification';

function Verif() {
  useScrollToTop();

  return (
    <Box
      mt={{ sm: '2%', md: '1%', lg: '1%' }}
      maxW="900px"
      mx="auto"
      height="70%"
      width="100%"
      zIndex="1"
      px={4}
      mb={10}
    >
      <PageHeader title="Document Verification" />
      <FileVerification />
    </Box>
  );
}

export default Verif;
