import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useColorMode,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';

import { MonopoleIcon } from '../Icons/Icons';
import GreenWebLogo from '../../assets/img/greenweb.png';

export default function Footer() {
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  let currentYear = new Date().getFullYear();
  const menuColor = useColorModeValue('white', '#000126');

  return (
    <Box
      px={{ base: '3%', sm: '5%', md: '5%', lg: '5%' }}
      pb="40px"
      left="0px"
      right="0px"
      zIndex="2"
      bottom="0px"
      backdropFilter="blur(25px)"
      mt="40px"
      position="relative"
    >
      <Flex height="100%" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Button
            height="43px"
            width="43px"
            background="transparent"
            _hover={{ background: 'transparent' }}
            _active={{ background: 'transparent' }}
            onClick={() => {
              navigate('/');
            }}
            display={{
              base: 'none',
              sm: 'none',
              md: 'contents',
              lg: 'contents',
            }}
          >
            <MonopoleIcon height="19px" width="43px" />
          </Button>

          <Flex alignItems="center">
            <Divider
              ml="20px"
              height="40px"
              width="2px"
              orientation="vertical"
              borderRadius="10px"
              mr="20px"
              bg="#353945"
              display={{
                base: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block',
              }}
            />
            <Image
              display={{
                base: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block',
              }}
              src={GreenWebLogo}
              alt="Green Web Monopole"
              height="55px"
            />

            <Text
              display={{
                base: 'none',
                sm: 'block',
                md: 'block',
                lg: 'block',
              }}
              fontSize="14px"
              fontWeight="700"
              ml="15px"
            >
              Monopole © {currentYear}. All rights reserved
            </Text>
            <Text
              display={{
                base: 'block',
                sm: 'none',
                md: 'none',
                lg: 'none',
              }}
              fontSize="14px"
              fontWeight="700"
              ml="15px"
            >
              Monopole © {currentYear}
            </Text>
          </Flex>
          <Text
            fontSize="14px"
            fontWeight="700"
            position="absolute"
            left="50%"
            bottom="15px"
            transform="translateX(-50%)"
          >
            Made with{' '}
            <span
              style={{
                paddingLeft: '3px',
                position: 'relative',
                top: '3px',
                fontSize: '20px',
              }}
            >
              🌍
            </span>
          </Text>
        </Flex>

        <Flex alignItems="center" gap={3}>
          <Menu isLazy>
            <MenuButton>
              <Box background="transparent" borderRadius="50px">
                More
              </Box>
            </MenuButton>
            <MenuList
              borderRadius="20px"
              alignItems="center"
              border="2px solid"
              background={menuColor}
            >
              {/*<MenuItem
                  borderRadius="20px"
                  _hover={{ border: "2px solid" }}
                  onClick={() => {
                    navigate("/legal");
                  }}
                >
                  <Text fontSize="14px" fontWeight="700">
                    Legal
                  </Text>
                </MenuItem>*/}
              <MenuItem
                background="transparent"
                borderRadius="50px"
                onClick={() => {
                  navigate('/terms-of-use');
                }}
              >
                <Text fontSize="14px" fontWeight="700">
                  Terms of Use
                </Text>
              </MenuItem>
              <MenuItem
                background="transparent"
                borderRadius="50px"
                onClick={() => {
                  navigate('/charter');
                }}
              >
                <Text fontSize="14px" fontWeight="700">
                  Charter
                </Text>
              </MenuItem>
              <MenuItem
                background="transparent"
                borderRadius="50px"
                onClick={() => {
                  navigate('/privacy-policy');
                }}
              >
                <Text fontSize="14px" fontWeight="700">
                  Privacy Policy
                </Text>
              </MenuItem>
              <MenuItem
                background="transparent"
                borderRadius="50px"
                onClick={() => {
                  navigate('/code-of-conduct');
                }}
              >
                <Text fontSize="14px" fontWeight="700">
                  Code of Conduct
                </Text>
              </MenuItem>
              <MenuItem
                background="transparent"
                borderRadius="50px"
                onClick={() => {
                  navigate('/documents-management');
                }}
              >
                <Text fontSize="14px" fontWeight="700">
                  Documents Management
                </Text>
              </MenuItem>
              <MenuItem
                background="transparent"
                borderRadius="50px"
                onClick={() => {
                  navigate('/credits');
                }}
              >
                <Text fontSize="14px" fontWeight="700">
                  Credits
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
          <Button
            background="transparent"
            onClick={toggleColorMode}
            borderRadius="50px"
          >
            {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
