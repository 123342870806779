import {
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
  FaTelegramPlane,
  FaDiscord,
  FaMedium,
} from 'react-icons/fa';

const Social = {
  0: {
    name: 'Twitter',
    logo: FaTwitter,
    link: 'https://twitter.com/monopole_mnpl',
  },
  1: {
    name: 'Discord',
    logo: FaDiscord,
    link: 'https://discord.gg/5CKjNHF3es',
  },
  2: {
    name: 'Linkedin',
    logo: FaLinkedinIn,
    link: 'https://www.linkedin.com/company/mnpl-officiel/',
  },
  3: {
    name: 'YouTube',
    logo: FaYoutube,
    link: 'https://www.youtube.com/channel/UCjA-8ripee4FWI6eTS1ex1g',
  },
  4: {
    name: 'Telegram',
    logo: FaTelegramPlane,
    link: 'https://t.me/mnpl_support_bot',
  },
  5: {
    name: 'Medium',
    logo: FaMedium,
    link: 'https://blog.monopole.network',
  },
};

export { Social };
