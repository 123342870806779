import { Flex, Image, Text, useColorMode } from '@chakra-ui/react';
import JoinTheMoveButton from './JoinTheMoveButton';

const ValueCard = ({ title, description, icon, alt }) => {
  const { colorMode } = useColorMode();

  let mt = { base: 50, lg: 10 };
  if (title == 'Ethics') {
    mt = { base: 50, lg: 10 };
  } else if (title === 'Legal Conformity') {
    mt = { base: 50, lg: 0 };
  } else if (title === 'Security') {
    mt = { base: 50, lg: 10 };
  }

  return (
    <Flex
      direction="column"
      width={{ base: '100%', sm: '80%', lg: '30%' }}
      alignItems="center"
      mt={mt}
      borderStyle="solid"
      borderWidth={1}
      p={10}
      borderRadius="2xl"
      borderColor={colorMode == 'dark' ? 'whiteAlpha.300' : 'gray.200'}
    >
      <Image src={icon} alt={alt} width="150px" />
      <Text fontSize="2xl" fontWeight="normal" marginY={5}>
        {title}
      </Text>
      <Text
        fontSize="md"
        textAlign="justify"
        color={colorMode == 'dark' ? 'whiteAlpha.700' : 'gray.500'}
      >
        {description}
      </Text>
      <JoinTheMoveButton link="https://app.testnet.monopole.network/" />
    </Flex>
  );
};

export default ValueCard;
