import React from 'react';
import { Text, Box, ListItem, UnorderedList, Link } from '@chakra-ui/react';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';

function TermsOfUse() {
  useScrollToTop();

  return (
    <>
      <Box
        px={{ sm: '10%', md: '10%', lg: '15%' }}
        mt={{ sm: '8%', md: '5%', lg: '5%' }}
        height="70%"
        width="100%"
        zIndex="1"
      >
        <PageHeader title="Terms of Use" />
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Website Terms of Use
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
          These Terms of Use (the “Terms”), together with any and all other
          documents referred to herein, set out the terms of use under which you
          may use the websites,{' '}
          <Link href="https://www.monopole.network" isExternal>
            https://www.monopole.network
          </Link>
          , and{' '}
          <Link href="https://www.monopole.network" isExternal>
            https://www.monopole.network
          </Link>{' '}
          (the “Monopole Websites”). Please read these Terms carefully and
          ensure that you understand them. Your agreement to comply with and be
          bound by these Terms is deemed to occur upon your first use of the
          Monopole Websites. If you do not agree to be bound by these Terms, you
          must stop using the Monopole Websites immediately.
        </Text>
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Information About Us
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
          The Monopole Websites are owned and operated by VelvetAngle LDA, a
          simplified joint stock company under Swiss law registered under
          registration , with its registered address located at 24 Grandchamps
          Street, 1971 SION. It is further specified that all the rights and
          undertakings resulting from the present Terms will be automatically
          transferred to Monopole once its process registration is completed
          with the clerck of office of the trade court of Sion(SW).
        </Text>
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Access to MNPL Websites
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
          Access to Monopole Websites is free of charge. It is your
          responsibility to make any and all arrangements necessary in order to
          access the Websites. The access is provided on an “as is” and “as
          available” basis. We may alter, suspend or discontinue any and all of
          the MNPL Websites (or any part of them) at any time and without
          notice. We will not be liable to you in any way if the Monopole
          Websites (or any part of them) are unavailable at any time and for any
          period.
        </Text>
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Acceptable Usage Policy
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
          You may only use the Monopole Websites in a manner that is lawful and
          that complies with the provisions of this clause. Specifically, a&#41;
          you must ensure that you comply fully with any and all local, national
          or international laws and/or regulations, b&#41; you must not use the
          Monopole Websites in any way, or for any purpose, that is unlawful or
          fraudulent, c&#41; you must not use the Monopole Websites to knowingly
          send, upload, or in any other way transmit data that contains any form
          of virus or other malware, or any other code designed to adversely
          affect computer hardware, software, or data of any kind; and, d&#41;
          you must not use the Monopole Websites in any way, or for any purpose,
          that is intended to harm any person or persons in any way.
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            We reserve the right to suspend or terminate your access to the MNPL
            Websites if you materially breach the provisions of this clause or
            any of the other provisions of these Terms. Specifically , we may
            take one or more of the following actions:
          </Text>
          <UnorderedList mb="20px" mt="20px" pl="10px">
            <ListItem mb="10px">
              Suspend, either temporarily or permanently, your right to access
              to Monopole Websites;
            </ListItem>
            <ListItem mb="10px">Issue you with a written warning;</ListItem>
            <ListItem mb="10px">
              Take legal proceedings against you for returns of any and all
              relevant costs on an indemnity basis resulting from your breach;
            </ListItem>
            <ListItem mb="10px">
              Take further legal action against you as appropriate;
            </ListItem>
            <ListItem mb="10px">
              Disclose such information to law enforcement authorities as
              required or as we deem reasonably necessary; and/or
            </ListItem>
            <ListItem mb="10px">
              Any other actions which we deem reasonably appropriate and lawful.
            </ListItem>
          </UnorderedList>
          We hereby exclude any and all liability arising out of any actions,
          including without limiting to those set out above, that we may take in
          response to breaches of these Terms.
        </Text>
        <Text
          mb="20px"
          mt="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Intellectual Property Rights
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
          Any and all intellectual property rights ("Intellectual Property
          Rights") associated with the Monopole Websites and its contents
          (including, but without limitation to text, graphics, user interfaces,
          photos, audio, video, computer code and all other forms of information
          or data) are the sole property of Monopole, and its affiliates or are
          licensed for the use of the Monopole Holding Group. The contents are
          protected by Intellectual Property Rights and other laws both in
          Portugal and other countries. Elements of the Monopole Websites are
          also protected by trade name, trade secret, unfair competition, and
          other laws and may not be copied or imitated in whole or in part. All
          customised graphics, icons, and other items that appear on the
          Monopole Websites are trademarks, service marks or trade name
          ("Marks") of Monopole, its affiliates or other entities that have
          granted us the right and licence to use such Marks and may not be used
          or interfered with in any manner without our express written consent.
          Except as otherwise expressly authorised by these Terms, you are not
          allowed to reproduce, copy, distribute, sell, rent, sub-licence,
          store, or in any other manner reuse the contents of the Monopole
          Websites in any way without our prior written permission or that of an
          appropriate third party. Except as expressly provided herein, Monopole
          Ltd. does not grant to you any express or implied rights to our
          Intellectual Property Rights or that of any third party.
          <Text
            lineHeight="24px"
            fontSize="16px"
            fontWeight="400"
            mt="10px"
            mb="10px"
          >
            You may, however, a&#41; access, view and use the Monopole Websites
            in a web browser (including any web browsing capability built into
            other types of software or app), b&#41; download the Monopole
            Websites (or any part of them) for caching, c&#41; print one copy of
            any page from the Monopole Websites, d&#41; download extracts from
            pages on the Monopole Websites, and e&#41; save pages from the
            Monopole Websites for later and/or offline viewing.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            You shall ensure that our status as the owner and author of the
            contents on the Monopole Websites, or that of identified licensors,
            as appropriate, must always be acknowledged.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
            You are not entitled to reuse any of the contents printed, saved or
            downloaded from the Monopole Websites for any commercial purposes
            without first obtaining a licence from us, or our licensors, as
            appropriate. This does not prohibit the normal access, viewing and
            use of the Monopole Websites whether by business users or consumers.
          </Text>
        </Text>
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Links to Other Sites
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
          Links to other sites may be included on the Monopole Websites. Unless
          expressly stated, these sites are not owned or controlled by us. We
          neither assume nor accept responsibility or liability for the content
          of these third party websites. The inclusion of a link to another
          website on the Monopole Websites is for information only and does not
          imply any endorsement of the websites themselves or of those in
          control of them.
        </Text>
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Liability and Disclaimers
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
          Nothing on the Monopole Websites constitutes advice on which you
          should rely. It is provided for information purposes only.
          Professional or specialist advice should always be sought before
          taking any action on the basis of any information provided on the
          Monopole Websites.
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Insofar as is permitted by law, we make no representation, warranty,
            or guarantee that the Monopole Websites will meet your requirements,
            that it will not infringe the rights of third parties, that it will
            be compatible with all software and hardware, or that it will be
            secure.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            We make reasonable efforts to ensure that the content on the
            Monopole Websites is complete, accurate, and up-to-date. We do not,
            however, make any representations, warranties or guarantees, whether
            express or implied, that the content is complete, accurate, or
            up-to-date.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            To the fullest extent permissible by law, we accept no liability to
            you for any loss or damage, whether foreseeable or otherwise, in
            contract, tort (including negligence), for breach of statutory duty,
            or otherwise, arising out of or in connection with the use of (or
            inability to use) the Monopole Websites or the use of or reliance
            upon any content included on the Monopole Websites.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            If you are a business user, we hereby exclude all implied
            conditions, warranties, representations or other terms that may
            apply to the Monopole Websites or the contents thereof. We will not
            be liable for any loss of profits, sales, business or revenue, loss
            of business opportunity, goodwill or reputation, loss of anticipated
            savings, business interruption, or for any indirect or consequential
            loss or damage.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
            We neither assume nor accept responsibility or liability arising out
            of any disruption or non-availability of the Monopole Websites
            resulting from external causes including, but not limited to, ISP
            equipment failure, host equipment failure, communications network
            failure, natural events, acts of war, or legal restrictions and
            censorship.
          </Text>
        </Text>
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Indemnification
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
          You agree to indemnify and hold Monopole services. and its officers,
          employees, and agents harmless, including costs and attorneys’ fees,
          from any claim or demand made by any third party due to or arising out
          of (i) your use of the Monopole Websites, (ii) your violation of these
          Terms, or (iii) your violation of applicable laws or regulations.
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
          We retain the right, at your expense, to assume the exclusive defense
          and control of any matter for which you are required to indemnify us,
          and you agree to cooperate with our defense of these claims. You agree
          not to settle any matter without our prior written consent. We will
          use reasonable efforts to notify you of any such claim, action or
          proceeding upon becoming aware of it.
        </Text>
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Viruses, Malware and Security
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
          We exercise all reasonable skill and care to ensure that the Monopole
          Websites are secure and free from viruses and other malware. However,
          you are also responsible for protecting your hardware, software, data
          and other material from viruses, malware, and other internet security
          risks.
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
          You must not deliberately introduce viruses or other malware, or any
          other material which is malicious or technologically harmful either to
          or via the Monopole Websites.
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
          You must not attempt to gain unauthorised access to any part of the
          Monopole Websites, the server on which the Monopole Websites are
          stored, or any other server, computer, or database connected to the
          Monopole Websites.
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
          You must not attack the Monopole Websites by means of a denial of
          service attack (“DoS”), a distributed denial of service attack
          (“DDoS”), or by any other means.
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
          By breaching the provisions mentioned in this clause, you may be
          committing a criminal offence under the relevant laws. Any and all
          such breaches will be reported to the relevant law enforcement
          authorities and we will cooperate fully with those authorities by
          disclosing your identity to them. Your right to use the MNPL Websites
          will cease immediately in the event of such a breach.
        </Text>
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Data Protection
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
          Your access and use of the Monopole Websites is also conditioned on
          your acceptance of our Privacy Policy. Our Privacy Policy sets out
          data protection practices and principles we apply at Monopole. Please
          read our Privacy Policy carefully before using the Monopole Websites.
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
          All personal information that we may use will be collected, processed,
          and held in accordance with the provisions of EU Regulation 2016/679
          General Data Protection Regulation (“GDPR”) and the Swiss Law No 78-17
          of 6 January 1978.
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
          For complete details of our collection, processing, storage, and
          retention of personal data including, but not limited to, the
          purpose(s) for which personal data is used, the legal basis or bases
          for using it, details of your rights and how to exercise them, and
          personal data sharing where applicable, please refer to our Privacy
          Policy.
        </Text>
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Law and Jurisdiction
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
          These Terms, and the relationship between you and us, whether
          contractual or otherwise, shall be governed by, and construed in
          accordance with, Swiss law, without regard to its conflict of law
          provisions.
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
          In the event of any controversy, dispute or claim (collectively
          “dispute”) between Monopole. and you arising out of or in connection
          with your use of the Monopole Websites, the parties shall attempt, in
          good faith, to resolve any such dispute among themselves in an
          amicable fashion. If the parties are not able to resolve any such
          dispute within 30 days, then either party may submit such dispute to
          mediation. If the dispute cannot be resolved through mediation within
          a month of the submission to mediation, then the parties shall be free
          to initiate legal proceedings and pursue any right or remedy available
          to them in which case the competent courts of Sion, Swiss will have
          the jurisdiction to hear the cases.
        </Text>
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Severance
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
          The provisions of these Terms are independent and severable from each
          other. If at any time any provision of the Terms is or becomes illegal
          or unenforceable in any respect under the law of any jurisdiction,
          this does not affect or impair: a&#41; the legality, validity, or
          enforceability in that jurisdiction of any other provision of these
          Terms; nor b&#41; the legality, validity, or enforceability under the
          law of any other jurisdiction of that or any other provision of the
          Terms.
        </Text>
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Changes to these Terms
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
          We may alter these Terms at any time. If we do so, details of the
          changes will be highlighted at the top of this page. Any such changes
          will become binding on you upon your first use of the Monopole
          Websites after the changes have been implemented. You are therefore
          advised to check this page from time to time.
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
          In the event of any conflict between the current version of these
          Terms and any previous versions, the provisions current and in effect
          shall prevail unless it is expressly stated otherwise.
        </Text>
        <Text
          mb="20px"
          lineHeight="24px"
          fontSize="18px"
          fontWeight="700"
          fontFamily="Montserrat"
        >
          Contacting Us
        </Text>
        <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
          If you have any questions we would be happy to answer them. Do not
          hesitate to contact us for any inquiries you might have via{' '}
          <Link href="https://monopole.network/toolbox/support" isExternal>
            this link.
          </Link>
        </Text>
      </Box>
    </>
  );
}

export default TermsOfUse;
