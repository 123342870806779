import React from 'react';
import { Text, Box, Flex, Link, Button, Image } from '@chakra-ui/react';
import background from '../../assets/img/plant_background.png';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';

function Label() {
  useScrollToTop();

  return (
    <Box
      mt={{ sm: '2%', md: '1%', lg: '1%' }}
      maxW="900px"
      mx="auto"
      height="70%"
      width="100%"
      zIndex="1"
      px={4}
    >
      <PageHeader title="Label" />
      <Flex direction={{ sm: 'column', md: 'row' }} w="100%" mb="50px">
        <Box
          w={{ md: '50%' }}
          display={{ base: 'none', sm: 'none', md: 'block' }}
        >
          <Image
            src={background}
            width="100%"
            height="auto"
            borderRadius={{ sm: '0px', md: '10px' }}
          />
        </Box>
        <Box
          w={{ sm: '100%', md: '50%' }}
          textAlign="justify"
          mt={{ sm: '20px', md: '0' }}
        >
          <Text lineHeight="24px" fontSize="20px" fontWeight="400" mb="25px">
            The <b>Monopole Proof of Impact label</b> is an audit service
            enabling each <b>project</b>, <b>brand</b> or <b>company</b> to
            promote its <b>social</b>, <b>ecological</b> and{' '}
            <b>economic commitment</b>.
          </Text>
          <Text lineHeight="24px" fontSize="20px" fontWeight="400" mb="25px">
            In order to achieve the <b>certification</b>, a prior qualification
            can be carried out and a tailor-made support performed.
          </Text>
          <Text lineHeight="24px" fontSize="20px" fontWeight="400" mb="25px">
            Our <b>goal</b> is to have a holistic approach to engagement. To
            allow everyone to aggregate his own and then help him to push his
            concept to the best - putting in relationships for financing,
            customer research, community support and commitment, duplication of
            concepts, ...-.
          </Text>
          <Text lineHeight="24px" fontSize="20px" fontWeight="400" mb="25px">
            As you can see, this <b>label</b> is much more than a{' '}
            <b>sign of commitment</b>. It is also:
          </Text>
          <Text
            lineHeight="28px"
            fontSize="20px"
            fontWeight="400"
            mb="10px"
            mt="20px"
            as="ul"
            pl="24px"
          >
            <li style={{ marginBottom: '10px' }}>
              The aggregation of <b>several other labels</b>
            </li>
            <li style={{ marginBottom: '10px' }}>
              The opportunity to <b>highlight your brand</b>
            </li>
            <li style={{ marginBottom: '10px' }}>
              Optimising <b>transparency</b>
            </li>
            <li style={{ marginBottom: '10px' }}>
              <b>Autonomous monitoring</b> of your radiation
            </li>
            <li style={{ marginBottom: '10px' }}>
              <b>Traceability</b> permitted by technology but optimising the
              frugality of technology consumption
            </li>
          </Text>
          <Text
            lineHeight="24px"
            fontSize="20px"
            fontWeight="400"
            mb="25px"
            mt="30px"
          >
            Proof of Impact is technology for brands, communities and our
            planet. A tangible and lasting balance that can be established in a
            decentralized and autonomous manner.
          </Text>
          <Flex
            mt="40px"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Link
              href={'https://app.monopole.network/services/label'}
              width="300px"
              height="64px"
              isExternal
              target="_blank"
              _focus={{ boxShadow: 'none' }}
            >
              <Button
                borderRadius="16px"
                background="#03CB88"
                width="100%"
                height="100%"
                color="white"
                _active={{
                  background: '#02B577', // Darkened for active state
                  transform: 'scale(0.98)', // Slightly scale down for a "pressed" effect
                }}
                _hover={{
                  background: '#02B577', // Slightly darkened green for hover
                  boxShadow: '0px 4px 10px rgba(3, 203, 136, 0.2)', // A subtle shadow
                  transform: 'scale(1.02)', // Slightly scale up for hover
                }}
                _focus={{
                  boxShadow: '0px 0px 0px 2px rgba(3, 203, 136, 0.4)', // Outline for focus
                  background: '#03CB88',
                }}
              >
                Beta App Label
              </Button>
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

export default Label;
