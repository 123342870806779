import React from 'react';
import {
  Box,
  VStack,
  Text,
  useColorModeValue,
  Icon,
  Button,
  Tooltip,
  Flex,
  Image,
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import {
  CheckCircleIcon,
  QuestionIcon,
  RepeatIcon,
  WarningIcon,
} from '@chakra-ui/icons';
import mnplIcon from '../../assets/img/brands/monopole.jpg';
import person from '../../assets/img/person.jpeg';
import useThemeColors from '../../hooks/useThemeColors';

const signatoryInfo = {
  '13Rrwf385ZfV95EwspZjQ4SrLSE6dqXnRN': {
    name: 'Monopole',
    logo: mnplIcon,
  },
};

const VerificationCard = ({
  verificationData,
  timestampInfo,
  onVerifyAnother,
}) => {
  const { backgroundColor, alpha100 } = useThemeColors();

  const errorColor = useColorModeValue('red.500', 'red.300');

  const errorMessages = {
    NOKEEEX: 'The file is not keeexed.',
    INVALID_IDX: 'The IDX is invalid.',
    INVALID_DS: 'The digital signature is invalid.',
    MISSING_SIGNATURE: 'The signature is missing.',
    INVALID_ADDRESS: 'The address is invalid.',
    // PROTECTED: 'The file is protected and verified.',
  };

  const getErrorMessage = (state) => {
    return errorMessages[state] || 'Unknown error';
  };

  const getSignatoryDetails = (identity) => {
    return (
      signatoryInfo[identity.publicKey] || {
        name: identity.publicKey,
        logo: person,
      }
    );
  };

  const formatDate = (timestamp) => {
    return DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATETIME_MED);
  };

  if (verificationData.state !== 'PROTECTED') {
    const errorMessage = getErrorMessage(verificationData.state);
    return (
      <Box
        textAlign="center"
        maxWidth="540px"
        p={5}
        boxShadow="md"
        borderWidth="1px"
        borderRadius="md"
        borderColor={alpha100}
        bg={backgroundColor}
      >
        <VStack spacing={4}>
          <Icon as={WarningIcon} w={10} h={10} color={errorColor} />
          <Text fontWeight="bold" color={errorColor}>
            Verification Error
          </Text>
          <Text textAlign="center">{errorMessage}</Text>

          <Button
            onClick={onVerifyAnother}
            size="xs"
            variant="outline"
            colorScheme="teal"
          >
            <Flex gap={1} align="center">
              <RepeatIcon />
              Verify Another Document
            </Flex>
          </Button>
        </VStack>
      </Box>
    );
  }

  return (
    <Box
      maxWidth="540px"
      p={5}
      boxShadow="md"
      borderWidth="1px"
      borderRadius="md"
      borderColor={alpha100}
      bg={backgroundColor}
    >
      <VStack spacing={4}>
        <Icon as={CheckCircleIcon} w={20} h={20} color={'green'} />

        <Text fontWeight="bold">{verificationData.name}</Text>
        <VStack spacing={1}>
          <Tooltip
            hasArrow
            label="The digital identity of the signatory of the file, which can be a natural person, a legal entity or a computer system. When using Bitcoin signatures, the identity of the signer can be verified by KeeeX (display of a name and/or logo) or remain pseudonymous (display of the public key)."
          >
            <Flex align="center" gap={1} cursor="pointer">
              <Text fontWeight="bold">Signatory</Text>
              <QuestionIcon w={3} mb={2} />
            </Flex>
          </Tooltip>
          {verificationData.identities.map((identity, index) => (
            <Flex key={index} align="center" gap={3}>
              <Image
                src={getSignatoryDetails(identity).logo}
                alt={getSignatoryDetails(identity).name}
                boxSize="50px"
              />
              <Text fontSize="sm">{getSignatoryDetails(identity).name}</Text>
            </Flex>
          ))}
        </VStack>

        <VStack spacing={1}>
          <Tooltip
            hasArrow
            label="A unique digital fingerprint (or hash) of the file that allows a file to be uniquely identified without revealing its contents and thus proving its integrity. Keeex encodes it in a humanized, easily pronounceable and indexable on the Internet and your computer."
          >
            <Flex align="center" gap={1} cursor="pointer">
              <Text fontWeight="bold">IDX</Text>
              <QuestionIcon w={3} mb={2} />
            </Flex>
          </Tooltip>
          <Text>{verificationData.mainIdx}</Text>
        </VStack>
        <VStack spacing={1}>
          <Tooltip
            hasArrow
            label="The date on which the IDX (fingerprint) of the file was signed by a timestamp server. This is usually the date the file was keeexed."
          >
            <Flex align="center" gap={1} cursor="pointer">
              <Text fontWeight="bold">Certification Date</Text>
              <QuestionIcon w={3} mb={2} />
            </Flex>
          </Tooltip>
          <Flex>{formatDate(timestampInfo.timestamp)}</Flex>
        </VStack>

        <Button
          onClick={onVerifyAnother}
          size="xs"
          variant="outline"
          colorScheme="teal"
        >
          <Flex gap={1} align="center">
            <RepeatIcon />
            Verify Another Document
          </Flex>
        </Button>
      </VStack>
    </Box>
  );
};

export default VerificationCard;
