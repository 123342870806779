import React, { useCallback, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  Center,
  Icon,
  Spinner,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useDropzone } from 'react-dropzone';
import VerificationCard from './VerificationCard';
import useKeeeXSDK from '../../hooks/useKeeeXSDK';
import useThemeColors from '../../hooks/useThemeColors';

const FileVerification = () => {
  const { backgroundColor } = useThemeColors();
  const [verifier, setVerifier] = useState(null);

  const instantiateVerifier = useCallback(() => {
    if (window.KXSDK) {
      const verifierInstance = new window.KXSDK.KeeexJS({
        'public-bitcoin-address': null,
        references: [],
        onlineIdentity: true,
        trustedAuthority: [],
      });
      setVerifier(verifierInstance);
      //console.log('Verifier instantiated', verifierInstance);
    }
  }, []);

  useKeeeXSDK(instantiateVerifier);

  const [verificationData, setVerificationData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timestampInfo, setTimestampInfo] = useState(null);
  const [error, setError] = useState('');

  // Function to reset to the initial state to verify another document
  const resetVerification = () => {
    setVerificationData(null);
  };

  const fetchTimestampInfo = useCallback(
    async (idx) => {
      setIsLoading(true);
      setError('');

      try {
        const infos = await verifier.getTimestampInfo(idx);
        setTimestampInfo(infos);
      } catch (error) {
        console.error('Error fetching timestamp info:', error);
        setError('Failed to fetch timestamp information.');
      } finally {
        setIsLoading(false);
      }
    },
    [verifier]
  );

  const handleFileDropped = useCallback(
    async (file) => {
      setIsLoading(true);
      setError('');
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const rawData = e.target.result;
          if (!verifier) {
            setError('Verifier is not initialized');
            setIsLoading(false);
            return;
          }
          const verificationData = await verifier.verifyDocument(
            file.name,
            rawData
          );

          if (verificationData && verificationData.state === 'PROTECTED') {
            await fetchTimestampInfo(verificationData.mainIdx);
          }

          setVerificationData(verificationData);
        } catch (error) {
          setError('Error verifying document');
          console.error('Error verifying document:', error);
        } finally {
          setIsLoading(false);
        }
      };

      reader.onerror = () => {
        setIsLoading(false);
        setError('Error reading file');
      };

      reader.readAsArrayBuffer(file);
    },
    [verifier, fetchTimestampInfo]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const fileToVerify = acceptedFiles[0];
        handleFileDropped(fileToVerify);
      }
    },
    [handleFileDropped]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'application/pdf',
  });

  return (
    <Center textAlign="center" flexDirection="column">
      {isLoading && <Spinner />}
      {error && (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      )}
      {!isLoading && verificationData && (
        <VerificationCard
          verificationData={verificationData}
          timestampInfo={timestampInfo}
          onVerifyAnother={resetVerification}
        />
      )}

      {!isLoading && !verificationData && (
        <Box
          {...getRootProps()}
          p={10}
          borderWidth="1px"
          borderStyle="dashed"
          borderColor="gray.300"
          borderRadius="lg"
          bg={backgroundColor}
          cursor="pointer"
          transition="all 0.2s ease-in-out"
          _hover={{ borderColor: 'gray.500' }}
          maxW="300px"
        >
          <input {...getInputProps()} />
          <VStack>
            <Icon as={AddIcon} boxSize={12} color="gray.400" />
            <Text fontSize="lg">
              Click or Drag & Drop a file to verify its authenticity
            </Text>
          </VStack>
        </Box>
      )}
    </Center>
  );
};

export default FileVerification;
