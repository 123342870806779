import globalWeek from '../../assets/img/brands/LOGO_GWA_UTA_Lockup_Horizontal_White_BG.png';
import climate from '../../assets/img/brands/climate.png';
import cop15 from '../../assets/img/brands/cop15.png';
import global from '../../assets/img/brands/global.png';
import investoragenda from '../../assets/img/brands/investoragenda.png';
import keeex from '../../assets/img/brands/keeex.png';
import onramper from '../../assets/img/brands/onramper.png';

const Partners = {
  0: {
    name: 'Keeex',
    img: keeex,
  },
  1: {
    name: 'Business for Nature Cop15',
    img: cop15,
  },
  2: {
    name: 'OnRamper',
    img: onramper,
  },
  3: {
    name: 'Investor Agenda',
    img: investoragenda,
  },
  4: {
    name: 'Global Week of Action',
    img: globalWeek,
  },
  5: {
    name: 'Crypto Climate Accord',
    img: climate,
  },
  6: {
    name: 'Global',
    img: global,
  },
  /*7: {
    name: 'Vialink',
    img: vialink,
  },*/
};

export { Partners };
