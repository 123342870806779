import { extendTheme } from '@chakra-ui/react';
import { globalStyles } from './styles';
import { buttonStyles } from './components/button';
import { font } from './foundations/fonts';
import { ThemeColor } from './foundations/theme';

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

export default extendTheme(
  {
    config,
    components: {
      Tooltip: {
        baseStyle: {
          zIndex: 9999, // Set a high value
        },
      },
    },
  },
  {
    breakpoints: {
      sm: '30em',
      md: '48em',
      lg: '62em',
      xl: '80em',
      '2xl': '96em',
    },
  }, // Breakpoints
  globalStyles,
  font, // Global styles
  buttonStyles, // Button styles
  ThemeColor
);
