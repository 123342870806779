import React from 'react';
// Chakra imports
import {
  Text,
  Box,
  OrderedList,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import PageHeader from '../../components/PageHeader';
import useScrollToTop from '../../hooks/useScrollToTop';

function CodeOfConduct() {
  useScrollToTop();

  return (
    <Box
      mt={{ sm: '2%', md: '1%', lg: '1%' }}
      maxW="900px"
      mx="auto"
      height="70%"
      width="100%"
      zIndex="1"
      px={4}
      textAlign="justify"
    >
      <PageHeader title="Code Of Conduct" />
      <Text lineHeight="24px" fontSize="18px" fontWeight="600" mb="10px">
        The Monopole Code of Conduct will help employees in their daily life.
        This document is an ethical reference for your daily business life.
      </Text>
      <Text lineHeight="24px" fontSize="18px" fontWeight="600" mb="40px">
        We are proposing great services for our users.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        The foundation of our success is the Respect of values we share.
      </Text>
      <UnorderedList mb="20px">
        <ListItem mb="10px">Respect for laws and regulations. </ListItem>
        <ListItem mb="10px">Respect for people. </ListItem>
        <ListItem mb="10px">Respect for the environment. </ListItem>
        <ListItem mb="10px">Respect for competition law regulations.</ListItem>
        <ListItem mb="10px">Respect for rules on insider trading. </ListItem>
        <ListItem mb="10px">Prevention of conflicts of interest.</ListItem>
        <ListItem mb="10px">
          Transparency and integrity of information.
        </ListItem>
      </UnorderedList>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        So please do read the Code and Monopole’s values, and follow both in
        spirit and letter, always bearing in mind that each of us has a personal
        responsibility to incorporate, and to encourage other Monopolers to
        incorporate, the principles of the Code and values into our work.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        And if you have a question or ever think that one of your fellow
        Monopolers or the company as a whole may be falling short of our
        commitment, please tell us. We want – and need – to hear from you.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        Who Must Follow Our Code?
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        We expect all of our employees and Board members to know and follow the
        Code. Failure to do so can result in disciplinary action, including
        termination of employment.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        Moreover, while the Code is specifically written for Monopolers and
        Board members.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        What If I Have a Code-Related Question or Concern?
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        If you have a question or concern, ask it. You can contact your
        manager/referent, Ethics & Compliance committee.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        If you have a misconduct concern about the CEO, a direct report to the
        CEO, or a C-level, you may also notify the Audit Committee of Monopole.
        To notify the Audit Committee, please mail your concern to:
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        Monopole
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Address :
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        24 grandchamps street, 1971 Champlan, Sion.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Or
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Mail: label@monopole.network
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="40px">
        You may report your concerns to the Audit Committee anonymously.
        However, the Audit Committee encourages you to provide your name and
        contact information so that we may contact you directly if necessary.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        No Retaliation
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="50px">
        Monopole prohibits retaliation against any Monopoler or former Monopoler
        who reports or participates in an investigation of a possible violation
        of our Code, policies, or the law. If you believe you are being
        retaliated against, please contact Ethics & Compliance service.
      </Text>
      <Text lineHeight="24px" fontSize="24px" fontWeight="600" mb="40px">
        I. Code of conduct
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
        Our users value our company not only because we deliver great profits
        and services, but because we hold ourselves to a higher standard in how
        we act, work, collaborate. Keeping the following principles in mind will
        help us to maintain that high standard:
      </Text>
      <OrderedList mb="50px">
        <ListItem mb="10px">
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Integrity
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
            Our reputation as a trusted company is our most valuable asset.
            Every employee needs to make sure Monopole keeps this trust. Trust
            with users, market, providers, partners. All of our communications
            and other interactions with everyone should increase their trust in
            us.
          </Text>
        </ListItem>
        <ListItem mb="10px">
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Usefulness
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
            Our products, features, and the services deployed to give full
            visibility on possible diversifications in line with our values,
            manage financial assets, using cryptocurrency to enjoy life. have
            many different types of users, from small to little community
            member, but one guiding principle: “Is what we are offering useful
            and good for the users interests?”
          </Text>
        </ListItem>
        <ListItem mb="10px">
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Privacy, Security, and Freedom of Expression
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            By using our platform, users agree to share their personal
            information. Every Monopoler needs to respect and protect the
            privacy and security of that information.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
            Our security procedures strictly limit access to and use of users’
            personal information, and require that each of us take measures to
            protect user data from unauthorized access. Know your
            responsibilities under these procedures, and collect, use, and
            access user personal information only as authorized by our Security
            Policies, our Privacy Policies, and applicable data protection laws.
          </Text>
        </ListItem>
        <ListItem mb="10px">
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Responsiveness
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Evolve thanks to feedback : We recognize the interest of relevant
            user feedback. The latter will be raised within the company's DAO.
            Any holder will therefore be able to govern, to take part in the
            daily life of the company.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
            We commit to have a clear communication with our users, whether
            questions, problems, or compliments. If something is broken we will
            fix it in a reasonable time.{' '}
          </Text>
        </ListItem>
        <ListItem mb="10px">
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Take Action
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400">
            Any time you notice our users aren’t being well-served, talk to us -
            let your manager know about it. Continually improving the platform
            and the services will provide us{' '}
          </Text>
        </ListItem>
      </OrderedList>
      <Text lineHeight="24px" fontSize="24px" fontWeight="600" mb="40px">
        II. Support and Respect Each Other
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        A. Our principles
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Monopole has an unwavering commitment to prohibiting and effectively
        responding to harassment, discrimination, misconduct, abusive conduct,
        and retaliation. To that end, Monopole adheres to these Guiding
        Principles:
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Commitment: Monopole sets a tone at the top of commitment to a
        respectful, safe, and inclusive working environment for all employees
        and members of the extended workforce.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Care: Monopole creates an environment with an emphasis on respect for
        each individual at all levels of the organization, including
        specifically by offering assistance and showing empathy to employees and
        members of the extended workforce throughout and after the complaint
        process.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Transparency: Monopole is open and transparent as an organization
        regarding the frequency with which complaints arise regarding
        harassment, discrimination, misconduct, abusive conduct, and
        retaliation, and the Company’s approach to investigating and responding
        to those allegations.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Fairness & Consistency: Monopole ensures that individuals are treated
        respectfully, fairly, and compassionately in all aspects of the company
        policies and values
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
        Accountability: Monopole holds all individuals responsible for their
        actions, and ensures that where appropriate, those individuals hold
        others accountable too.
      </Text>
      <Text
        mb="20px"
        lineHeight="24px"
        fontSize="18px"
        fontWeight="700"
        fontFamily="Montserrat"
      >
        B. Our practice
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Consistent with the Principles, Monopolers are expected to do their
        utmost to create a supportive work environment, where everyone has the
        opportunity to reach their fullest potential, and be free from
        harassment, intimidation, bias, and unlawful discrimination.
      </Text>
      <OrderedList mb="50px">
        <ListItem mb="10px">
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Equal Opportunity Employment
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
            Employment here is based solely upon individual merit and
            qualifications directly related to professional competence. We
            strictly prohibit unlawful discrimination or harassment on the basis
            of race, color, religion, veteran status, national origin, ancestry,
            pregnancy status, sex, gender identity or expression, age, marital
            status, mental or physical disability, medical condition, sexual
            orientation, or any other characteristics protected by law. We also
            make all reasonable accommodations to meet our obligations under
            laws protecting the rights of the disabled.
          </Text>
        </ListItem>
        <ListItem mb="10px">
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Harassment, Discrimination, and Bullying
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
            Monopole prohibits discrimination, harassment and bullying in any
            form – verbal, physical, or visual, as discussed more fully in our
            Policy Against Discrimination, Harassment and Retaliation. If you
            believe you’ve been bullied, harassed, or discriminated against by
            anyone at Monopole, or by a Monopole partner, we strongly encourage
            you to immediately report the incident to your referent, C-level or
            both. Similarly, supervisors and managers who learn of any such
            incident should immediately report it to the ethics and compliance
            committee. The ethics and compliance committee will promptly and
            thoroughly investigate any complaints and take appropriate action.
          </Text>
        </ListItem>
        <ListItem mb="10px">
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Drugs and Alcohol
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Our position on substance abuse is simple: It is incompatible with
            the health and safety of our employees, and we don’t permit it.
            Consumption of alcohol is not tolerated during working hours.
            Exception for special event schedule one week before allowed by the
            ethics and compliance committee members.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            During this event, please use good judgment and never drink in a way
            that leads to impaired performance or inappropriate behavior,
            endangers the safety of others, or violates the law. Illegal drugs
            in our offices or at sponsored events are strictly prohibited.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            If a manager has reasonable suspicion to believe that an employee’s
            use of drugs and/or alcohol may adversely affect the employee’s job
            performance or the safety of the employee or others in the
            workplace, the manager may request alcohol and/or drug screening.
            Reasonable suspicion may be based on objective symptoms such as the
            employee’s appearance, behavior, or speech.
          </Text>
        </ListItem>
        <ListItem mb="10px">
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Remote work details
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Establish regular check-ins. Managers should set up regular meetings
            with their whole team and one-on-one check-ins with individuals.
            Emphasis on clear, regular communication can help bridge any gaps
            that come from working remotely. Make sure everyone on the team
            knows how to use the selected communications tools.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Model behavior. Managers can set the example – if business casual
            clothing is expected on video calls, be sure to dress appropriately.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Reinforce work/life balance. Managers can help reinforce work/life
            balance by ensuring employees are taking breaks and taking time off
            from work – working at home shouldn’t be a 24/7 job. Determine if
            employees must have set office hours or if they can split their day.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Set clear goals. Managers and employees should determine priorities
            and how they’ll evaluate success. Regular goal setting can help keep
            productivity high despite the challenges of remote work.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Allow for multiple technology options. E-mail, texts, video chats,
            phone calls, or using group messaging services can help give a team
            a sense of cohesiveness despite being dispersed.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Be consistent in implementing and enforcing rules. While facing a
            pandemic has brought on new challenges and required new levels of
            flexibility, employees should still be making clear progress on
            goals and meeting company standards. Managers should avoid
            micromanaging but still need to provide feedback and support if team
            members are off track.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Allow time for social interaction. Whether at the end or beginning
            of team calls, or a special time set aside for team chat, supporting
            social interactions is essential to avoid feelings of isolation.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Offer support. Employees are likely under more pressure than ever
            and likely face much uncertainty. Ensuring that you offer support,
            whether it’s time to talk or additional resources, during this time
            is crucial.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Provide the necessary tools. Provide remote employees with the
            equipment to do their job correctly. This may include laptops,
            headsets, and mobile phones.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Be flexible when possible. Many employees may be trying to balance
            caring for elderly family members, children who aren’t in school or
            daycare, and pets who aren’t used to everyone at home. A little
            flexibility can go a long way.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Most of all, celebrate every success. As the pandemic surges again,
            many employees will be facing lockdowns or quarantines again. Any
            form of celebration or recognition can help people feel more
            connected and happy, despite the challenges of navigating so many
            unknowns.
          </Text>
        </ListItem>
      </OrderedList>
      <Text lineHeight="24px" fontSize="24px" fontWeight="600" mb="40px">
        III. Avoid Conflicts of Interest
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        When you are in a situation in which competing loyalties could cause you
        to pursue a personal benefit for you, your friends, or your family at
        the expense of Monopole or our users, you may be faced with a conflict
        of interest.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        {' '}
        All of us should avoid conflicts of interest and circumstances that
        reasonably present the appearance of a conflict.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        When considering a course of action, ask yourself whether the action
        you’re considering could create an incentive for you, or appear to
        others to create an incentive for you, to benefit yourself, your friends
        or family, or an associated business at the expense of MNPL.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        If the answer is “yes,” the action you’re considering is likely to
        create a conflict-of-interest situation, and you should avoid it.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Below, we provide guidance in seven areas where conflicts of interest
        often arise:
      </Text>
      <UnorderedList>
        <ListItem mb="10px">Personal investments</ListItem>
        <ListItem mb="10px">
          Outside employment, advisory roles, board seats, and starting your own
          business
        </ListItem>
        <ListItem mb="10px">Business opportunities found through work</ListItem>
        <ListItem mb="10px">
          Accepting gifts, entertainment, and other business courtesies
        </ListItem>
        <ListItem mb="20px">
          Use of Monopole products and services, tools
        </ListItem>
      </UnorderedList>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        In each of these situations, the rule is the same – if you are
        considering entering into a business situation that creates a conflict
        of interest, don’t.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        If you are in a business situation that may create a conflict of
        interest, or the appearance of a conflict of interest, review the
        situation with your manager and the Ethics & Compliance committee.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
        Please note, if your personal situation or goal changes in the future,
        be sure to connect with your manager and/or the Ethics & Compliance
        committee to allow you to pursue your career and your personal choices.
      </Text>
      <OrderedList>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Personal Investments
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Avoid making personal investments in companies that are Monopole
            competitors or business partners when the investment might cause, or
            appear to cause, you to act in a way that could harm Monopole.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            When determining whether a personal investment creates a conflict of
            interest, consider the relationship between the business of the
            outside company, Monopole’s business, and what you do at Monopole,
            including whether the company has a business relationship with
            Monopole that you can influence, and the extent to which the company
            competes with Monopole.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            You should also consider:
          </Text>
          <OrderedList>
            <ListItem>
              <Text
                lineHeight="24px"
                fontSize="16px"
                fontWeight="400"
                mb="10px"
              >
                Any overlap between your specific role at Monopole and the
                company’s business,
              </Text>
            </ListItem>
            <ListItem>
              <Text
                lineHeight="24px"
                fontSize="16px"
                fontWeight="400"
                mb="10px"
              >
                The significance of the investment, including the size of the
                investment in relation to your net worth,
              </Text>
            </ListItem>
            <ListItem>
              <Text
                lineHeight="24px"
                fontSize="16px"
                fontWeight="400"
                mb="10px"
              >
                Your ownership percentage of the company,{' '}
              </Text>
            </ListItem>
            <ListItem>
              <Text
                lineHeight="24px"
                fontSize="16px"
                fontWeight="400"
                mb="20px"
              >
                The extent to which the investment gives you the ability to
                manage and control the company.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Outside Employment, Advisory Roles, Board Seats, and Starting Your
            Own Business
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Avoid accepting employment, advisory positions, or board seats with
            Monopole competitors or business partners when your judgment could
            be, or could appear to be, influenced in a way that could harm
            Monopole.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
            Additionally, because board seats come with fiduciary obligations
            that can make them particularly tricky from a conflict of interest
            perspective, you should notify your manager before accepting a board
            seat with any outside company. Monopole board members and employees
            who are VP and above should also notify Ethics & Compliance.
            Finally, do not start your own business if it will compete with
            Monopole.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Business Opportunities Found Through Work
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
            Business opportunities discovered through your work here belong
            first to Monopole, except as otherwise agreed to by Monopole.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Personal Relationships at Work
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
            Certain relationships within Monopole may compromise or be seen to
            compromise your ability to perform your job responsibilities, may
            create uncomfortable or conflicted positions, and may raise issues
            of fairness, favoritism, or harassment. Therefore, be mindful of how
            your relationships within Monopole could impact or be perceived by
            others. Romantic, physical or familial relationships are not
            permitted between a Monopoler and another Monopoler or member of the
            extended workforce where one individual is in a position to exercise
            authority or supervision over the other. This prohibition includes
            any situation where one person is in the reporting line of the
            other, For more information, see the Policy on Personal
            Relationships at Work.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Accepting Gifts, Entertainment, and Other Business Courtesies
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Accepting gifts, entertainment, and other business courtesies from a
            Monopole competitor or business partner can easily create the
            appearance of a conflict of interest, especially if the value of the
            item is significant.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Monopole’s Non-Government Related Gifts & Client Entertainment
            Policy provides specific guidance on when it is appropriate for
            Monopolers to accept gifts, entertainment, or any other business
            courtesy (including discounts or benefits that are not made
            available to all Monopolers) from any of our competitors or business
            partners.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Generally, acceptance of inexpensive “token” non-cash gifts is
            permissible. In addition, infrequent and moderate business meals and
            entertainment with clients and infrequent invitations to attend
            local sporting events and celebratory meals with clients can be
            appropriate aspects of many Monopole business relationships,
            provided that they aren’t excessive and don’t create the appearance
            of impropriety. Before accepting any gift or courtesy, consult the
            Non-Government Related Gifts & Client Entertainment Policy, and be
            aware that you may need to obtain manager approval.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
            Contact Ethics & Compliance if you have any questions. See the
            discussion of Anti-Bribery Laws in Section VII(d) for guidance on
            when it is appropriate to give gifts and business courtesies in the
            course of doing Monopole business.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Use of Monopole Products and Services
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="20px">
            Avoiding potential conflicts of interest also means that you should
            not use Monopole products, services, internal tools, or information
            in a way that improperly benefits you or someone you know or creates
            the appearance that you have an unfair advantage over users outside
            of Monopole. For example, you should never approve Monopole
            accounts, services, or credits for yourself, your friends, or family
            members. Similarly, you should not use the tools, information, or
            access that you have as a Monopoler to participate in or to generate
            a financial benefit for yourself or others from invalid ad traffic
            (IVT) on Monopole products, such as generating IVT, purchasing or
            selling IVT (except for the purposes of company-sanctioned
            research), or linking to (or appearing to link to) business partners
            that may be engaging in IVT. If you find yourself subject to a
            conflict of interest regarding the use of Monopole’s products,
            services, tools, or information, discuss the situation with your
            manager, Legal, or Ethics & Compliance.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Reporting
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="50px">
            Compliance Steering Committee all matters involving Monopole C-Level
            – approved under this section of the Code, and will periodically
            report to the Monopole Nominating and Corporate Governance Committee
            all matters involving Monopole management and C-level approved under
            this section.
          </Text>
        </ListItem>
      </OrderedList>
      <Text Text lineHeight="24px" fontSize="24px" fontWeight="600" mb="40px">
        IV. Preserve Confidentiality
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        We get a lot of press attention around our innovations and our culture,
        and that’s usually fine. However, certain kinds of company information,
        if leaked prematurely into the press or to competitors, can hurt our
        product launches, eliminate our competitive advantage and prove costly
        in other ways. Our responsibilities extend beyond not revealing
        Confidential Monopole material
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        – We must also:
      </Text>
      <UnorderedList>
        <ListItem mb="10px">
          Properly secure, label, and (when appropriate) dispose of Confidential
          Monopole material;
        </ListItem>
        <ListItem mb="10px">
          Safeguard Confidential information that Monopole receives from others
          under non-disclosure agreements;
        </ListItem>
        <ListItem mb="10px">
          Take steps to keep our trade secrets and other confidential
          intellectual property secret.
        </ListItem>
      </UnorderedList>
      <OrderedList>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Confidential Information
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Make sure that information that is classified as “Need to Know” or
            “Confidential” in Monopole’s Data Classification Guidelines is
            handled in accordance with those Guidelines and Monopole’s Data
            Security Policy. At times, a particular project or negotiation may
            require you to disclose Need to Know or Confidential information to
            an outside party: Disclosure of that information should be on an
            “only as needed” basis and only under a non-disclosure agreement. In
            addition, Monopole policy may require a prior security assessment of
            the outside party that is to receive the confidential information.
            Be sure to conduct the appropriate due diligence and have the
            appropriate agreement in place before you disclose the information.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            There are, of course, “gray areas” in which you will need to apply
            your best judgment in making sure you don’t disclose any
            confidential information. Be cautious in what advice or insight you
            provide or, better yet, ask for guidance from Ethics & Compliance.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            And don’t forget about pictures you and your guests take at Monopole
            – it is up to you to be sure that those pictures don’t disclose
            confidential information.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Finally, some of us will find ourselves having family or other
            personal relationships with people employed by our competitors or
            business partners. As in most cases, common sense applies. Don’t
            tell your significant other or family members anything confidential,
            and don’t solicit confidential information from them about their
            company.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Monopole Partners
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Just as you are careful not to disclose confidential Monopole
            information, it’s equally important not to disclose any confidential
            information from our partners. Don’t accept confidential information
            from other companies without first having all parties sign an
            appropriate Non-disclosure Agreement approved by Legal. Even after
            the agreement is signed, try only to accept as much information as
            you need to accomplish your business objectives.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Data protection
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Be sure to protect the confidential information of Monopole.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            You may have access to confidential information through
            collaborations, access to Monopole networks, or simply through
            casual interactions. Don’t access or use confidential information
            when authorized and reasonably necessary for valid business purposes
            within the scope of your work at Monopol. Take all reasonable steps
            to maintain the confidentiality of any such information just as you
            would for Monopole confidential information.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Don’t disclose any confidential information about Monopole company,
            including financial, partner, business, technical, or IP
            information, before obtaining appropriate sign-off from Legal, which
            may include getting consent from affected the company.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Competitors/Former Employers
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            We respect our competitors and want to compete with them fairly. But
            we don’t want their confidential information. The same goes for
            confidential information belonging to any Monopoler’s former
            employers. If an opportunity arises to take advantage of a
            competitor’s or former employer’s confidential information, don’t do
            it. Should you happen to come into possession of a competitor’s
            confidential information, contact Legal immediately.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Outside Communications
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            You probably know that our policy is to control and protect
            confidential proprietary information.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            You must be ensure your outside communications (including online and
            social media posts) do not disclose confidential proprietary
            information.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            People can consider that you are speaking on behalf of Monopole so
            weigh every word or every picture you wish to share.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            The same applies to communications with the press. Finally, check
            with your manager and Corporate Communications before accepting any
            public speaking engagement on behalf of the company.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="50px">
            In general, before making any external communication or disclosure,
            you should consult our Employee Communications Policy and our
            Communications and Disclosure Policy.
          </Text>
        </ListItem>
      </OrderedList>
      <Text Text lineHeight="24px" fontSize="24px" fontWeight="600" mb="40px">
        V. Protect Monopole’s Assets
      </Text>
      <OrderedList>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Intellectual Property
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Monopole’s intellectual property rights (our trademarks, logos,
            copyrights, trade secrets..) are among our most valuable assets.
            Unauthorized use can lead to their loss or serious loss of value.
            You must respect all copyright and other intellectual property laws,
            including laws governing the fair use of copyrights, trademarks, and
            brands. You must never use Monopole’s (or its affiliated entities’)
            logos, marks, or other protected information or property for any
            business or commercial venture without pre-clearance from the
            Marketing Team.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            We strongly encourage you to report any suspected misuse of
            trademarks, logos, or other Monopole intellectual property to Legal.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            A word about open source – Monopole is committed to open source
            software development. Consistent with our policy of respecting the
            valid intellectual property rights of others, we strictly comply
            with the license requirements under which open source software is
            distributed. Failing to do so may lead to legal claims against
            Monopole, as well as significant damage to the company’s reputation
            and its standing in the open source community. Please seek guidance
            from Ethics and Compliance before incorporating open source code
            into any Monopole product, service, or internal project.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Company Equipment
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Monopole gives us the tools and equipment we need to do our jobs
            effectively, but counts on us to be responsible and not wasteful
            with the Monopole stuff we are given. The company funds, equipment,
            and other physical assets are not to be requisitioned for purely
            personal use. Please ask your manager if you have any doubts.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            The Network
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Monopole’s communication facilities (which include both our network
            and the hardware that uses it, like computers and mobile devices)
            are a critical aspect of our company’s property, both physical and
            intellectual. Be sure to follow all security policies. If you have
            any reason to believe that our network security has been violated –
            for example, you lose your laptop or smartphone or think that your
            network password may have been compromised – please promptly report
            the incident to Information Security. For more information, consult
            Monopole's Security Policy.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Physical Security
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            If you’re not careful, people may steal your stuff. Always secure
            your laptop, important equipment, and your personal belongings, even
            while on Monopole’s premises
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Use of Monopole’s Equipment and Facilities
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Anything you do use Monopole’s corporate electronic facilities
            (e.g., our computers, mobile devices, network, etc.) or store on our
            premises (e.g., letters, memos, and other documents) might be
            disclosed to people inside and outside the company. For example,
            Monopole may be required by law (e.g., in response to a subpoena or
            warrant) to monitor, access, and disclose the contents of corporate
            email, voicemail, computer files, and other materials on our
            electronic facilities or on our premises. In addition, the company
            may monitor, access, and disclose employee communications and other
            information on our corporate electronic facilities or on our
            premises where there is a business need to do so, such as protecting
            employees and users, maintaining the security of resources and
            property, or investigating suspected employee misconduct.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Employee Data
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="50px">
            We collect and store personal information from employees around the
            world. Access this data only in line with local law and Monopole
            internal policies, and be sure to handle employee data in a manner
            that is consistent with Monopole’s Data Classification and
            Employment Data Guidelines and other Monopole policies.
          </Text>
        </ListItem>
      </OrderedList>
      <Text Text lineHeight="24px" fontSize="24px" fontWeight="600" mb="40px">
        VI. Ensure Financial Integrity and Responsibility
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Financial integrity and fiscal responsibility are core aspects of
        corporate professionalism. This is more than accurate reporting of our
        financials, though that’s certainly important. The money we spend on
        behalf of Monopole is not ours; it’s the company’s and, ultimately, our
        shareholders’. Each person at Monopole– not just those in Finance – has
        a role in making sure that money is appropriately spent, our financial
        records are complete and accurate, and internal controls are honored.
        This matters every time we hire a new vendor, expense something to
        Monopole, sign a new business contract, or enter into any deals on
        Monopole’s behalf.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        To make sure that we get this right, Monopole maintains a system of
        internal controls to reinforce our compliance with legal, accounting,
        tax, and other regulatory requirements in every location in which we
        operate.
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Stay in full compliance with our system of internal controls, and don’t
        hesitate to contact Ethics & Compliance or Audit Committee if you have
        any questions. What follows are some core concepts that lie at the
        foundation of financial integrity and fiscal responsibility here at
        Monopole.
      </Text>
      <OrderedList>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Spending Monopole’s Money
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            A core Monopole value has always been to spend money wisely. When
            you submit an expense for reimbursement or spend money on Monopole’s
            behalf, make sure that the cost is reasonable, directly related to
            company business, and supported by appropriate documentation. Always
            record the business purpose (e.g., if you take someone out to dinner
            on Monopole, always record in our expense reimbursement tool the
            full names and titles of the people who attended as well as the
            reason for the dinner) and comply with other submission
            requirements. If you’re uncertain about whether you should spend
            money or submit an expense for reimbursement, check with your
            referent. Referents are responsible for all money spent and expenses
            incurred by their direct reports and should carefully review such
            spend and expenses before approving.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Signing a Contract
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Each time you enter into a business transaction on Monopole’s
            behalf, there should be documentation recording that agreement,
            approved by the Legal Department. Signing a contract on behalf of
            Monopole is a very big deal. Never sign any contract on behalf of
            Monopole unless all of the following are met:
          </Text>
          <UnorderedList>
            <ListItem>
              <Text
                lineHeight="24px"
                fontSize="16px"
                fontWeight="400"
                mb="10px"
              >
                You are authorized to do so under our Signature Authority and
                Approval Policy. If you are unsure whether you are authorized,
                ask your manager
              </Text>
            </ListItem>
            <ListItem>
              <Text
                lineHeight="24px"
                fontSize="16px"
                fontWeight="400"
                mb="10px"
              >
                The contract has been approved by Legal. If you are using an
                approved Monopole form contract, you don’t need further Legal
                approval unless you have made changes to the form contract or
                are using it for other than its intended purpose
              </Text>
            </ListItem>
            <ListItem>
              <Text
                lineHeight="24px"
                fontSize="16px"
                fontWeight="400"
                mb="10px"
              >
                You have studied the contract, understood its terms and decided
                that entering into the contract is in Monopole’s interest
              </Text>
            </ListItem>
          </UnorderedList>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            All contracts at Monopole should be in writing and should contain
            all of the relevant terms to which the parties are agreeing –
            Monopole does not permit “side agreements,” oral or written.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Recording Transactions
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            If your job involves the financial recording of our transactions,
            make sure that you’re fully familiar with all of the Monopole
            policies that apply, including our Revenue Recognition Policy and
            our Purchasing Policy.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Immediately report to the Audit Committee any transactions that you
            think are not being recorded correctly.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Reporting Financial or Accounting Irregularities
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            It goes without saying (but we’re going to say it anyway) that you
            should never, ever interfere in any way with the auditing of
            Monopole’s financial records. Similarly, you should never falsify
            any record or account, including time reports, expense accounts, and
            any other Monopole records.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Familiarize yourself with our Reporting of Financial and Accounting
            Concerns Policy. If you suspect or observe any of the conduct
            mentioned above or, for that matter, any irregularities relating to
            financial integrity or fiscal responsibility, no matter how small,
            immediately report them to Ethics & Compliance.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Hiring Suppliers
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            As Monopole grows, we enter into more and more deals with suppliers
            of equipment and services. We should always strive for the best
            possible deal for Monopole. This almost always requires that you
            solicit competing bids to make sure that you’re getting the best
            offer. While price is very important, it isn’t the only factor worth
            considering. Quality, service, reliability, and the terms and
            conditions of the proposed deal may also affect the final decision.
            Please do not hesitate to contact the Purchasing Team if you have
            any questions regarding how to procure equipment or services.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Retaining Records
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="50px">
            It’s important that we keep records for an appropriate length of
            time. The Monopole Records Retention Policy suggests minimum record
            retention periods for certain types of records. These are great
            guidelines, but keep in mind that legal requirements, accounting
            rules, and other external sources sometimes specify longer retention
            periods for certain types of records, and those controls where
            applicable. In addition, if asked by Legal to retain records
            relevant to a litigation, audit, or investigation, do so until Legal
            tells you retention is no longer necessary. If you have any
            questions regarding the correct length of time to retain a record,
            contact the Records Retention Team.
          </Text>
        </ListItem>
      </OrderedList>
      <Text Text lineHeight="24px" fontSize="24px" fontWeight="600" mb="40px">
        VII. Obey the Law
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
        Monopole takes its responsibilities to comply with laws and regulations
        very seriously and each of us is expected to comply with applicable
        legal requirements and prohibitions. While it’s impossible for anyone to
        know all aspects of every applicable law, you should understand the
        major laws and regulations that apply to your work. Take advantage of
        Legal and Ethics & Compliance to assist you here. A few specific laws
        are easy to violate unintentionally and so are worth pointing out here:
      </Text>
      <OrderedList>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Competition Laws
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Most countries have laws – known as “antitrust,” “competition,” or
            “unfair competition” laws – designed to promote free and fair
            competition.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Generally speaking, these laws prohibit
          </Text>
          <OrderedList>
            <ListItem mb="10px">
              Arrangements with competitors that restrain trade in some way,
            </ListItem>
            <ListItem mb="10px">
              Abuse of intellectual property rights, and{' '}
            </ListItem>
            <ListItem mb="10px">
              Use of market power to unfairly disadvantage competitors.
            </ListItem>
          </OrderedList>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Certain conduct is absolutely prohibited under these laws, and could
            result in your imprisonment, not to mention severe penalties for
            Monopole.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Examples of prohibited conduct include:
          </Text>
          <UnorderedList>
            <ListItem mb="10px">
              Agreeing with competitors about prices
            </ListItem>
            <ListItem mb="10px">
              agreeing with competitors to rig bids or to allocate customers or
              markets
            </ListItem>
            <ListItem mb="10px">
              agreeing with competitors to boycott a supplier or customer
            </ListItem>
          </UnorderedList>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Other activities can also be illegal, unfair, or create the
            appearance of impropriety. Such activities include:
          </Text>
          <UnorderedList>
            <ListItem mb="10px">
              Sharing competitively sensitive information (e.g., prices, costs,
              market distribution, etc.) with competitors
            </ListItem>
            <ListItem mb="10px">
              Entering into a business arrangement or pursuing a strategy with
              the sole purpose of harming a competitor
            </ListItem>
            <ListItem mb="10px">
              Using Monopole size or strength to gain an unfair competitive
              advantage
            </ListItem>
          </UnorderedList>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Although the spirit of these laws is straightforward, their
            application to particular situations can be quite complex.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Monopole is committed to competing fair and square, so please
            contact Ethics & Compliance if you have any questions about the
            antitrust laws and how they apply to you. Any personnel found to
            have violated Monopol’s Antitrust Policies will, subject to local
            laws, be disciplined, up to and including termination of employment.
            If you suspect that anyone at the company is violating the
            competition laws, notify Ethics & Compliance immediately.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Insider Trading Laws
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            As we said earlier, internally we share information, including
            non-public information, about MNPL’s business operations pretty
            freely (think of TGIF). In addition, you may overhear a hallway
            conversation or come across a memo at a copy machine, either of
            which might involve confidential information. To use this non-public
            information to buy or sell stock, or to pass it along to others so
            that they may do so, could constitute insider trading. Insider
            trading not only violates this Code, it violates the law. Don’t do
            it.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Anti-bribery Laws
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            Like all businesses, Monopole is subject to lots of laws, both EU.
            and non-EU., that prohibit bribery in virtually every kind of
            commercial setting. The rule for us at Monopole is simple – don’t
            bribe anybody, anytime, for any reason.
          </Text>
        </ListItem>
        <ListItem>
          <Text lineHeight="24px" fontSize="18px" fontWeight="400" mb="10px">
            Non-government relationships
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="10px">
            You should be careful when you give gifts and pay for meals,
            entertainment, or other business courtesies on behalf of Monopole.
            We want to avoid the possibility that the gift, entertainment, or
            other business courtesy could be perceived as a bribe, so it’s
            always best to provide such business courtesies infrequently and,
            when we do, to keep their value moderate.
          </Text>
          <Text lineHeight="24px" fontSize="16px" fontWeight="400" mb="50px">
            Consult Monopole’s Non-Government Related Gifts and Client
            Entertainment Policy before providing any business courtesies and
            contact Ethics & Compliance if you have any questions.
          </Text>
        </ListItem>
      </OrderedList>
      <Text Text lineHeight="24px" fontSize="24px" fontWeight="600" mb="40px">
        VIII. Conclusion
      </Text>
      <Text lineHeight="24px" fontSize="16px" fontWeight="600" mb="20px">
        Monopole aspires to be a different kind of company. It’s impossible to
        spell out every possible ethical scenario we might face. Instead, we
        rely on one another’s good judgment to uphold a high standard of
        integrity for ourselves and our company. We expect all Monopolers to be
        guided by both the letter and the spirit of this Code. Sometimes,
        identifying the right thing to do isn’t an easy call. If you aren’t
        sure, don’t be afraid to ask questions of your manager, Legal or Ethics
        & Compliance.
      </Text>
    </Box>
  );
}

export default CodeOfConduct;
