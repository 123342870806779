import { useEffect } from 'react';

// Custom hook to scroll to the top
function useScrollToTop() {
  useEffect(() => {
    const element = document.getElementById('layout');
    if (element) {
      element.scrollTop = 0;
    }
  }, []);
}

export default useScrollToTop;
