import { Text, Center } from '@chakra-ui/react';

export default function PageHeader({ title }) {
  return (
    <Center>
      <Text
        mt="10px"
        mb={{ base: '20px', sm: '20px', md: '30px' }}
        fontSize={{ base: '30px', sm: '30px', md: '30px', lg: '40px' }}
        fontWeight="700"
        fontFamily="Montserrat"
        color="#03CB88"
      >
        {title}
      </Text>
    </Center>
  );
}
